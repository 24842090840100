import AccountList from "../../components/home/AccountList";
import { IoAlarm, IoBan, IoCloseSharp, IoDesktopSharp, IoEye, IoFingerPrint, IoLockClosed, IoPerson, IoRefreshSharp, IoReload, IoReloadCircleSharp, IoThumbsDownSharp, IoTime } from "react-icons/io5";
import Spinner from "../../components/Spinner";
import { CalcularExpiracion, currencyFormat, message, setUnixToTimeFormat } from "../../utils/Funciones.js";
import { MdAutorenew, MdAvTimer, MdMail, MdPersonPin } from "react-icons/md";
import { FaCircleCheck, FaCoins, FaMobileScreen, FaScreenpal, FaShopify, FaSquareCheck, FaUserClock, FaUserTag, FaWhatsapp } from "react-icons/fa6";
import { Pagination, PaginationItem, Stack, Button, IconButton, MenuItem, Tooltip, FormControl, Select } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { FaClipboard, FaShippingFast } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import 'moment/locale/es';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import debounce from 'lodash.debounce';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { BsCalendar2CheckFill } from "react-icons/bs";
import { SET_TO_DEFAULT_RESPONSE, changeAutorenewService, getByPageByUserId, renew as renewService } from "../../store/features/slices/ventaSlice";
import { uriSerialized } from "../../utils/uriSerialized";
import { FiSearch } from "react-icons/fi";
import { Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";
import Modal from "../../components/modal/Modal";
import { SET_CASH_USER } from "../../store/features/slices/userSlice";
import Switch from 'react-switch';
import SpinnerData from "../../components/SpinnerData";
import { UPDATE_LAST10_OF_CATEGORY } from "../../store/features/slices/categorySlice";
import Socket, { authSocket, logoutSocket } from '../../utils/Socket';
import { useTranslation } from 'react-i18next';

const UserOrders = ({ }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userToken, user, userInfo } = useSelector((state) => state.userReducer);
  const { ventasByPage, isMutation, totalFilter, total } = useSelector((state) => state.ventaReducer);
  const { subcategories } = useSelector((state) => state.categoryReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [limit, setLimit] = useState(30);
  const [infopedido, setInfopedido] = useState('')
  const [verdetalles, SetVerDetalles] = useState({
    open: false,
    categoryname: "",
    categorylogo: "",
    categoryrenovable: false,
    correo: "",
    passwordcuenta: "",
    data: {},
    copied: false,
    autorenew: false
  })
  const [renew, SetRenew] = useState({
    open: false,
    quantitymonths: 0,
    costo: 0,
    type: "",
    id: "",
    catname: "",
    logo: "",
    ventaId: "",
    minmonths: 1
  })

  useEffect(() => {
    if (isMutation.success) {
      message(isMutation.message, 'success', 5)
      dispatch(SET_TO_DEFAULT_RESPONSE())
    }

    const queryOptions = {
      id: userToken && user?.id,
      page: currentPage,
      limit: limit,
      search: searchTerm.trim(),
    }
    const query = queryOptions ? "?" + uriSerialized(queryOptions) : ""
    dispatch(getByPageByUserId(query))
  }, [dispatch, searchTerm.trim(), currentPage, limit, isMutation.success]);


  useEffect(() => {
    if (isMutation.updated) {
      if (isMutation.extra?.isActionAutorenew) {
        message(isMutation.message, 'success', 5)
        SetVerDetalles({ ...verdetalles, data: { ...verdetalles.data, autorenovable: isMutation.extra.autorenovable } })
      } else {
        message(isMutation.message, 'success', 5)
        Socket.emit('renewStreaming', { subcategory: isMutation.extra.subcategory, newHistory: isMutation.extra.newHistory, user: { email: userInfo?.email } })
        SetRenew({ ...renew, open: false, quantitymonths: 0, costo: 0, type: "", id: "", catname: "", logo: "", ventaId: "" })
        dispatch(SET_CASH_USER({ type: 'DECREASE', amount: isMutation.extra.amount }))
        dispatch(UPDATE_LAST10_OF_CATEGORY(isMutation.extra.newHistory))
      }
      dispatch(SET_TO_DEFAULT_RESPONSE())
    }
  }, [isMutation.updated]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const HandleOpenDetalles = (venta) => {

    SetVerDetalles({
      ...verdetalles,
      ['open']: !verdetalles.open,
      ['data']: venta,
      ['categoryname']: venta.subcategory.name,
      ['categorylogo']: venta.subcategory.logo,
      categoryrenovable: venta.subcategory.renovable,
      ['correo']: venta.correo.correo,
      ['passwordcuenta']: venta.correo.passwordcuenta,
      autorenew: venta.autorenovable,
    })

    if (venta) {
      var InformacionPedido =
        `*${t('infoaccount')}* 
*${t('service')} :* ${venta.subcategory.name}
*${t('email')} :* ${venta.correo.correo}
*${t('password')} :* ${venta.correo.passwordcuenta}
*${t('servicetype')} :* ${venta.type}
*${t('expiration')} :* ${moment(venta.expirationdate).format("dddd, DD [de] MMMM [de] YYYY")}
${venta.isprofileblocked ?
          `*${t('infoprofiles')}* 
*${t('name')} :* ${venta.dataperfiles[0].name}
*${t('pin')} :* ${venta.dataperfiles[0].password}` : ''}`

      setInfopedido(InformacionPedido)
    }
  }

  const HandleOpenRenew = (venta) => {
    var actualcosto = 0
    var minimomonths = 1
    if (userToken && user) {
      subcategories.find((sub) => {
        if (venta.subcategory._id === sub._id) {
          minimomonths = Number(sub.minmonths)
          var isVip = false
          var discountvip = 0
          if (userInfo?.vip?.state === 'ACTIVE' && new Date(userInfo?.vip?.expirationdate) > new Date()) {
            isVip = true
            discountvip = sub.discountvip
          }

          if (venta.type === '1 pantalla') {
            if (userInfo?.reseller) {
              actualcosto = sub.costoreseller - discountvip
            } else {
              actualcosto = ((sub.costouser - (sub.costouser * sub.discountuser) / 100)) - discountvip
            }
          } else if (venta.type === 'cuenta completa') {
            if (userInfo?.reseller) {
              actualcosto = sub.costoreseller - discountvip
            } else {
              actualcosto = ((sub.costouser - (sub.costouser * sub.discountuser) / 100)) - discountvip
            }
          }
        }
      })
    } else {
      message('Token inválido, este problema se resolverá reingresando al sistema', 'error', 4)
    }

    SetRenew({ ...renew, open: true, costo: actualcosto, type: venta.type, catname: venta.subcategory.name, logo: venta.subcategory.logo, id: venta._id, ventaId: venta.ventaId, minmonths: minimomonths })
  }

  const HandleRenewService = () => {
    if (userToken && user) {
      if (renew.quantitymonths > 0) {
        const options = {
          _id: renew.id,
          ventaId: renew.ventaId,
          costo: renew.costo,
          quantitymonths: renew.quantitymonths,
          minmonths: renew.minmonths
        }
        dispatch(renewService(options))
      } else {
        message(t('alertmonthsrenew'), 'error', 4)
      }
    } else {
      message(t('alertinvalidtoken'), 'error', 4)
    }
  }

  const onCopy = (text) => {
    SetVerDetalles({ ...verdetalles, copied: true });
    message(text, 'success', 2)
  }

  const handleChangePage = (e, p) => {
    setCurrentPage(p);
  };

  const changeHandleSearch = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1);
  };

  const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 500), []);

  const onChangeSearchTermClear = e => {
    setSearchTerm("");
    setCurrentPage(1)
    document.getElementById("search").value = "";
  };

  const debouncedDispatch = useMemo(() => debounce((_id, newState) => {
    dispatch(changeAutorenewService({ _id: _id, newState: newState }))
  }, 700), [dispatch]);

  const handleChangeAutorenewService = (newState) => {
    SetVerDetalles({ ...verdetalles, autorenew: !verdetalles.autorenew })
    debouncedDispatch(verdetalles.data?._id, newState);
  };

  return (
    <AccountList>

      {/* HEADER PAGES */}
      <div className="fixed mt-8 p-4 flex flex-wrap text-brand-900 dark:text-brand-100 uppercase w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] bg-gradient-to-r from-white dark:from-brand-900 backdrop-blur-lg z-30 rounded-b-xl">
        <div className="w-full h-14 text-lg md:text-2xl flex items-center">
          <div className="flex items-center w-full">
            <FaShopify size={65} style={{ marginRight: 10 }} />
            {t('mystreamings')}
            <div class="flex items-center justify-center px-3 ml-3 text-2xl font-medium bg-brand-200 dark:bg-brand-800 text-brand-900 dark:text-brand-200 rounded-full">
              {!isMutation.loading && total}
            </div>
          </div>
        </div>
        <div className="mt-3 !w-full md:!w-[450px] md:!max-w-[450px]">
          <InputGroup className="w-full md:w-[450px] md:max-w-[450px]">
            <InputLeftElement pointerEvents="none" className="!mt-[14px] !ml-2">
              <FiSearch size={24} className="text-brand-900 dark:text-brand-100" />
            </InputLeftElement>
            <Input size='lg' autoFocus id="search" variant='flushed' onChange={debouncedChangeHandler} type="text" placeholder={t('searchorders') + '...'} className="flex w-full md:w-[450px] md:max-w-[450px] !h-14 !pl-10 !items-center !justify-center !border-2 rounded-xl !bg-brand-200 
            dark:!bg-brand-950 !text-sm focus:!ring-lightPrimary dark:focus:!ring-darkPrimary focus:!border-lightPrimary dark:focus:!border-darkPrimary 
            !outline-none !border-brand-300 dark:!border-brand-800 !text-brand-900 dark:!text-white !relative !placeholder-brand-500" />
            <InputRightElement className="!mt-[8px] !mr-1">
              <IconButton onClick={onChangeSearchTermClear} className={`mr-2 ${searchTerm.trim().length > 0 ? 'scale-100' : 'scale-0'} transition-all duration-500 text-brand-600 dark:text-brand-300`}>
                <IoCloseSharp size={20} className="text-brand-900 dark:text-brand-100" />
              </IconButton>
            </InputRightElement>
          </InputGroup>
        </div>
      </div>

      <div className="w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] mb-0 mt-[160px] pb-3 pt-5 md:pt-5 lg:pt-10 fixed z-20 bg-white/10 dark:bg-[#00000023] backdrop-blur-lg flex flex-col md:flex-col lg:flex-row items-center">
        <div className="w-full flex items-center justify-center md:justify-end text-brand-800 dark:text-brand-200 mt-0 h-10">
          <div className="hidden md:block">
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                page={currentPage}
                onChange={handleChangePage}
                variant="text"
                color="primary"
                shape="rounded"
                size="large"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
                className="bg-white dark:bg-brand-900"
              />
            </Stack>
          </div>
          <div className="block md:hidden">
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                page={currentPage}
                onChange={handleChangePage}
                variant="text"
                color="primary"
                shape="rounded"
                size="small"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
                className="bg-white dark:bg-brand-900"
              />
            </Stack>
          </div>
        </div>
      </div>

      <Modal
        open={verdetalles.open}
        onClose={() => SetVerDetalles({
          ...verdetalles,
          open: false,
          categoryname: "",
          categorylogo: "",
          categoryrenovable: false,
          correo: "",
          passwordcuenta: "",
          data: []
        })}
        headerTitle={t('orderdetails')}
        size='base'
      >
        <div style={{ filter: verdetalles.data.suspended ? 'grayscale(100%)' : 'none' }} className="relative w-full">
          <div className="w-full mb-3 text-brand-900 dark:text-brand-200">
            <div className="grid grid-wrap w-full">
              <div className="flex inline-flex items-center">
                <LazyLoadImage
                  className="object-cover max-h-12 max-w-12 rounded-full z-10"
                  alt={`No image ${verdetalles.categoryname}`}
                  effect="opacity"
                  src={`/images/logos/${verdetalles.categorylogo}`}
                />
                <div className="flex items-center">
                  <div className="ml-3 text-xl font-semibold">{verdetalles.categoryname}</div>
                </div>
              </div>
            </div>
          </div>
          {
            userToken && userInfo?.reseller &&
            <div className="w-full flex inline-flex flex-wrap mt-5 text-brand-900 dark:text-brand-200 bg-brand-100 dark:bg-brand-900 shadow-xl shadow-brand-300 dark:shadow-none rounded-lg mb-5 pb-5">
              <label className="absolute -mt-4 text-lg ml-5">{t('infoclient')}</label>
              <div className="grid grid-wrap w-full md:w-6/12 mt-4 pl-5 pr-5 pt-3 pb-3">
                <LazyLoadImage
                  className="object-cover min-h-6 max-h-6 max-w-8 min-w-8 rounded-xs"
                  alt={`No image`}
                  effect="opacity"
                  src={`/images/flags/4x3/${verdetalles.data.contactoInfo && verdetalles.data.contactoInfo.countrycode}.svg`}
                />
                <div className="flex inline-flex items-center text-sm p-2 bg-white dark:bg-black rounded-lg mt-2">
                  <FaWhatsapp size={24} className="mr-2" />
                  <span>{verdetalles.data.contactoInfo && verdetalles.data.contactoInfo.celular}</span>
                </div>
                <div className="flex inline-flex items-center text-sm p-2 bg-white dark:bg-black rounded-lg mt-2">
                  <MdPersonPin size={24} className="mr-2" />
                  <span>{verdetalles.data.contactoInfo && verdetalles.data.contactoInfo.alias}</span>
                </div>
              </div>
            </div>
          }

          <div className="w-full flex inline-flex flex-wrap mt-5 text-brand-900 dark:text-brand-200 bg-white dark:bg-brand-900 shadow-lg shadow-brand-300 dark:shadow-black rounded-lg mb-5 pb-5">
            <label className="absolute -mt-4 text-lg ml-5">{t('infogeneral')}</label>
            <div className="grid grid-wrap w-full md:w-7/12 mt-4 pl-5 pr-5 pt-3 pb-3">

              <div className="flex inline-flex items-center text-sm p-2 bg-brand-100 dark:bg-black rounded-lg"><IoTime size={28} className="mr-2" />
                {setUnixToTimeFormat("created_at", verdetalles.data.created_at)}</div>
              {moment(verdetalles.data.created_at).format("yyyy-MM-DDTHH:mm") !== moment(verdetalles.data.last_update).format("yyyy-MM-DDTHH:mm") &&
                <div className="flex inline-flex items-center text-sm p-2 bg-brand-100 dark:bg-black rounded-lg mt-2"><span className="text-green-400 flex items-center"><IoReload size={28} className="mr-2" />{setUnixToTimeFormat("renew", verdetalles.data.last_update)}</span></div>}

              <div className="flex inline-flex items-center text-sm p-2 bg-brand-100 dark:bg-black rounded-lg mt-2"><IoAlarm size={28} className={`${CalcularExpiracion(verdetalles.data.expirationdate) === 'Caducado' ? 'text-red-400' : 'text-green-500'} mr-2`} />
                <span className={`${CalcularExpiracion(verdetalles.data.expirationdate) === 'Caducado' ? 'text-red-400' : 'text-green-400'}`}>{moment(verdetalles.data.expirationdate).format("dddd, DD [de] MMMM [de] yyyy")}</span></div>

              <div className="flex inline-flex items-center text-sm p-2 bg-brand-100 dark:bg-black rounded-lg mt-2"><BsCalendar2CheckFill size={24} className="mr-2" />
                <span>{verdetalles.data.totalmonths < 2 ? verdetalles.data.totalmonths + ' ' + t('month') : verdetalles.data.totalmonths + ' ' + t('month')}</span></div>
              <div className="flex inline-flex items-center p-2 bg-brand-100 dark:bg-black rounded-lg mt-2">
                {
                  verdetalles.data.suspended ?
                    <div className="flex inline-flex items-center text-orange-500 text-sm">
                      <FaUserClock size={24} className="mr-2" />{t('suspended')}</div>
                    :
                    <>{verdetalles.data.state === "complete" && <div className="flex inline-flex items-center text-green-400 text-sm">
                      <FaCircleCheck size={24} className="mr-2" />{t('completed')}</div>}
                      {verdetalles.data.state === "pending" && <div className="flex inline-flex items-center text-yellow-400 text-sm">
                        <MdAvTimer size={28} className="mr-2" />{t('pending')}</div>}
                      {verdetalles.data.state === "rejected" && <div className="flex inline-flex items-center text-red-400 text-sm">
                        <IoThumbsDownSharp size={28} className="mr-2" />{t('rejected')}</div>}
                      {verdetalles.data.state === "locked" && <div className="flex inline-flex items-center text-red-400 text-sm">
                        <IoLockClosed size={28} className="mr-2" />{t('locked')}</div>}</>
                }

              </div>
              <div className="flex items-center text-sm p-2 bg-brand-100 dark:bg-black rounded-lg mt-2"><IoDesktopSharp size={28} className="mr-2" />{verdetalles.data.type}</div>
              {
                verdetalles.categoryrenovable &&
                <div className="relative flex items-center text-sm p-2 bg-brand-100 dark:bg-black rounded-lg mt-2"><MdAutorenew size={28} className="mr-2" />{t('autorenew')} {verdetalles.data.autorenovable ? <span className="text-green-500 dark:text-green-400 ml-2">{t('enabled')}</span> : <span className="text-red-500 dark:text-red-400 ml-2">{t('disabled')}</span>}
                  {
                    verdetalles.data?.subcategory?.renovable &&
                    <div className="flex items-center absolute right-5">
                      <Switch
                        onChange={handleChangeAutorenewService}
                        checked={verdetalles.autorenew}
                        offColor="#888"
                        onColor="#17d244"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={25}
                        width={40}
                      />
                    </div>
                  }

                </div>
              }
            </div>
          </div>
          <div className="mt-5 text-brand-900 dark:text-brand-200 bg-white dark:bg-brand-900 shadow-lg shadow-brand-300 dark:shadow-black p-5 rounded-lg">
            <label className="absolute -mt-9 text-lg">{t('infoaccountlower')}</label>
            <div className="flex items-center w-full bg-brand-100 dark:bg-black p-3 rounded-lg">
              <div className="w-[35px] flex items-center"><MdMail size={28} className="text-yellow-500" /></div>
              <div className="w-[calc(100%-55px)] text-sm flex items-center">
                <span className="flex items-center text-base mr-1">{t('email')}:</span>
                <div className="truncate ...">
                  {verdetalles.data.state === "locked" ?
                    <span className="text-red-400"> {t('locked')}</span>
                    : verdetalles.data.suspended ? <span className="text-orange-500"> {t('suspended')}</span>
                      : verdetalles.correo}
                </div>
              </div>
              <div className="w-[20px] flex items-center">
                {
                  (verdetalles.data.state === "complete" && !verdetalles.data.suspended) && <Tooltip title={t('copy')} arrow={true}>
                    <div>
                      <CopyToClipboard text={(verdetalles.data.state === "locked" || verdetalles.data.suspended) ? '' : verdetalles.correo} onCopy={() => onCopy(t('emailcopied'))}>
                        <FaClipboard size={20} className="text-brand-900 dark:text-brand-200 cursor-pointer" />
                      </CopyToClipboard>
                    </div>
                  </Tooltip>
                }
              </div>
            </div>
            <div className="relative flex items-center w-full bg-brand-100 dark:bg-black p-3 mt-3 rounded-lg">
              <div className="w-[35px] flex items-center"><IoFingerPrint size={28} className="mr-1 text-red-500" /></div>
              <div className="w-[calc(100%-55px)] text-sm flex items-center">
                <span className="flex items-center text-base mr-1">{t('password')}: </span>
                <div className="truncate ...">
                  {verdetalles.data.state === "locked" ?
                    <span className="text-red-400"> {t('locked')}</span>
                    : verdetalles.data.suspended ? <span className="text-orange-500"> {t('suspended')}</span>
                      : verdetalles.passwordcuenta}
                </div>
              </div>
              <div className="w-[20px] flex items-center">
                {
                  (verdetalles.data.state === "complete" && !verdetalles.data.suspended) && <Tooltip title={t('copy')} arrow={true}>
                    <div>
                      <CopyToClipboard text={(verdetalles.data.state === "locked" || verdetalles.data.suspended) ? '' : verdetalles.passwordcuenta} onCopy={() => onCopy(t('passwordcopied'))}>
                        <FaClipboard size={20} className="text-brand-900 dark:text-brand-200 cursor-pointer" />
                      </CopyToClipboard>
                    </div>
                  </Tooltip>
                }
              </div>
            </div>
          </div>

          {verdetalles.data.isprofileblocked &&
            <div className="w-full flex inline-flex flex-wrap mt-5 text-brand-900 dark:text-brand-200">
              <div className="w-full flex flex-wrap mt-5 bg-white dark:bg-brand-900 shadow-lg shadow-brand-300 dark:shadow-black rounded-lg p-5">
                <label className="absolute -mt-9 text-lg">{t('infoprofileslower')}</label>
                {
                  verdetalles.data.state !== "locked" ? (
                    verdetalles.data.dataperfiles?.map((dp, index) => {
                      return (
                        <div key={index} className="w-full">
                          <div className="w-full mt-3">
                            <div className="grid grid-wrap w-full md:w-4/12">
                              <div className="flex inline-flex items-center">
                                <div className="m-2 w-14 h-14 rounded-full bg-brand-100 dark:bg-black flex inline-flex items-center"><IoPerson className="w-10 h-10 z-30 flex inline-flex m-auto" /></div>
                                <div className="grid self-center">
                                  <div className="ml-2 text-base font-semibold">{dp.name}</div>
                                  <div className="ml-2 text-sm flex inline-flex items-center bg-brand-100 dark:bg-black p-3"><IoFingerPrint size={24} /><span className="text-yellow-700 dark:text-yellow-500 font-semibold text-xl">{dp.password === "" ? t('wopassword') : dp.password}</span></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ) : <span className="text-red-400 ml-5">{t('locked')}</span>
                }
              </div>
            </div>
          }

          <div className='flex items-center justify-center mt-10 mb-5'>
            {
              (verdetalles.data.state === "complete" && !verdetalles.data.suspended) &&
              <Tooltip title={t('copy')} arrow={true}>
                <div>
                  <CopyToClipboard text={infopedido} onCopy={() => onCopy(t('infocopied'))}>
                    <FaClipboard size={45} className="text-brand-900 dark:text-brand-200 cursor-pointer" />
                  </CopyToClipboard>
                </div>
              </Tooltip>
            }

          </div>
        </div>
      </Modal>

      <Modal
        open={renew.open}
        onClose={() => SetRenew({ ...renew, open: false, quantitymonths: 0, costo: 0, type: "", id: "", catname: "", logo: "", ventaId: "" })}
        headerTitle={t('renweservice')}
        size='base'
      >
        <div className="realtive w-full">
          <div className="grid grid-wrap w-full md:w-4/12 mb-5">
            <div className="flex inline-flex items-center">
              <span className="absolute -mb-[100px] ml-[0px] text-brand-900 dark:text-brand-200 
              text-base rounded-lg bg-brand-50 dark:bg-brand-950 p-2 flex inline-flex 
              items-center justify-center">{renew.ventaId}</span>
              <img class="w-14 h-14 z-30 rounded-full flex inline-flex"
                src={`/images/logos/${renew.logo}`} alt={`No image ${renew.catname}`} />
              <div className="grid self-center">
                <div className="ml-2 text-base font-semibold text-brand-900 dark:text-brand-200">{renew.catname}</div>
              </div>
            </div>
          </div>
          <div className="w-full mb-5 mt-12">
            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">{t('howmuchmonthforrenew')}?</label>
            <div className="relative">
              <FormControl fullWidth>
                <Select
                  name="quantitymonths"
                  className="flex items-center"
                  variant="outlined"
                  value={renew.quantitymonths}
                  onChange={(e) => SetRenew({ ...renew, quantitymonths: e.target.value })}
                >
                  {
                    renew.minmonths === 1 ?
                      [1, 3, 6, 12].map((q) => {
                        return <MenuItem value={q} key={q} className="flex items-center">{q} {q === 1 ? t('month') : t('months')}</MenuItem>
                      })

                      :
                      <MenuItem value={renew.minmonths} key={renew.minmonths} className="flex items-center">{renew.minmonths} {t('months')}</MenuItem>

                  }
                </Select>
              </FormControl>
            </div>
          </div>
          {
            renew.quantitymonths > 0 &&
            <div className="text-3xl mt-5 mb-5">
              {userToken && userInfo.reseller ?
                <span className="text-yellow-400">{t('totalforpay')} : {currencyFormat(renew.minmonths === 1 ? (renew.costo * renew.quantitymonths) : renew.costo)}</span>
                :
                <span className="text-green-400">{t('totalforpay')} : {currencyFormat(renew.minmonths === 1 ? (renew.costo * renew.quantitymonths) : renew.costo)}</span>
              }
            </div>
          }

          <div className="w-full mt-2">
            <Button
              onClick={HandleRenewService}
              sx={{
                "&.Mui-disabled": {
                  background: "#525252",
                  color: "#a3a3a3"
                }
              }}
              disabled={(isMutation.uloading || renew.quantitymonths < 1) ? true : false}
              type="submit" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
              size='large' variant="contained" color="success" startIcon={isMutation.uloading ? <Spinner w={'35px'} h={'35px'} /> : <IoRefreshSharp size={24} />}>{isMutation.uloading ? 'Renovando...' : 'Renovar'}</Button>
          </div>

        </div>
      </Modal>
      <div className="relative w-full mt-[285px]">
        {
          !isMutation.loading ? ventasByPage.length > 0 ? (
            <div>
              {
                ventasByPage.map((venta) => {

                  return (
                    <div className={`p-4 relative w-full shadow-xl shadow-neutral-200 dark:shadow-none cursor-pointer md:w-12/12 bg-white dark:bg-brand-900 text-brand-900 dark:text-brand-200 flex flex-wrap mb-14 rounded-lg animation`} key={venta._id}>
                      <label className="absolute -top-9 -left-0 p-2 flex items-center text-brand-800 dark:text-brand-200 text-base rounded-lg bg-white dark:bg-brand-900">
                        <span className="text-[12px]">{venta.ventaId}</span>
                        {
                          (userToken && userInfo?.reseller && venta.contactoInfo.alias) &&
                          <span className="flex items-center ml-5 text-yellow-500 dark:text-yellow-400">
                            <MdPersonPin size={32} className="mr-1" />
                            <span className="text-xl">{venta.contactoInfo && venta.contactoInfo.alias}</span>
                            <span className="ml-4">
                              <Tooltip title="copiar" arrow={true}>
                                <div>
                                  <CopyToClipboard text={venta.contactoInfo && venta.contactoInfo.alias} onCopy={() => onCopy(t('aliascopied'))}>
                                    <FaClipboard size={16} className="text-yellow-500 dark:text-yellow-400 cursor-pointer" />
                                  </CopyToClipboard>
                                </div>
                              </Tooltip>
                            </span>
                          </span>
                        }
                      </label>
                      <div style={{ filter: venta.suspended ? 'grayscale(100%)' : 'none' }} className="relative grid grid-wrap w-full md:w-full lg:w-[18%]">
                        <div className="flex inline-flex items-center">
                          <LazyLoadImage
                            className="object-cover max-w-[45px] min-w-[45px] max-h-[45px] min-h-[45px] z-10 rounded-full flex inline-flex"
                            alt={`No image ${venta.subcategory.name}`}
                            effect="opacity"
                            src={`/images/logos/${venta.subcategory.logo}`}
                          />
                          <div className="grid self-center">
                            <div className="ml-2 text-sm font-semibold truncate ...">{venta.subcategory.name}</div>
                            {
                              venta.suspended ?
                                <div className="ml-2 text-xs">{t('suspended')}</div>
                                :
                                <>
                                  <div className="ml-2 text-xs">{venta.state === "pending" && <span className="text-yellow-400">{t('emailpendingforactivation')}</span>}</div>
                                  <div className="ml-2 text-xs">{venta.state === "complete" && <span className="text-brand-800 dark:text-brand-200">{venta.correo.correo}</span>}</div>
                                  <div className="ml-2 text-xs">{(venta.state === "locked" && CalcularExpiracion(venta.expirationdate) === "Caducado" && venta.subcategory.renovable) && <span className="text-red-400">{t('servicelocked')} - {t('norenew')} </span>}</div>
                                  <div className="ml-2 text-xs">{(venta.state === "locked" && CalcularExpiracion(venta.expirationdate) === "Caducado" && !venta.subcategory.renovable) && <span className="text-red-400">{t('servicelocked')} - {t('expired')}</span>}</div>
                                  <div className="ml-2 text-xs">{(venta.state === "locked" && CalcularExpiracion(venta.expirationdate) !== "Caducado") && <span className="text-red-400">{t('servicelocked')}</span>}</div>
                                  <div className="ml-2 text-xs">{venta.state === "rejected" && <span className="text-red-500">{t('servicerejected')}</span>}</div>
                                </>
                            }

                            <div className="ml-2 text-xs truncate ...">{setUnixToTimeFormat('created_at', venta.created_at)}</div>
                          </div>
                        </div>
                      </div>

                      <div style={{ filter: venta.suspended ? 'grayscale(100%)' : 'none' }} className="relative grid md:grid lg:flex grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-3 items-center w-full md:w-full lg:w-[62.33%] mt-5 md:mt-4 lg:mt-0">

                        <div className="w-full md:w-full lg:w-2/12 grid">
                          <div className="flex items-center justify-center uppercase text-sm">{t('totalcost')}</div>
                          <div className="flex items-center justify-center">
                            <div className="flex inline-flex items-center">
                              {
                                venta.user?.reseller ?
                                  <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2">
                                    <FaCoins size={24} className="mr-2" />{currencyFormat(venta.totalamount)}
                                  </div>
                                  :
                                  <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2">
                                    <FaUserTag size={24} className="mr-2" />{currencyFormat(venta.totalamount)}
                                  </div>
                              }
                            </div>
                          </div>
                        </div>

                        <div className="w-full md:w-full lg:w-4/12 grid">
                          <div className="flex items-center justify-center uppercase text-sm">{t('expiration')}</div>
                          <div className="flex items-center justify-center">
                            <div className="flex inline-flex items-center">
                              {venta.expirationdate === null ?
                                <div class="text-center text-yellow-950 dark:text-yellow-50 bg-gradient-to-r from-yellow-400 dark:from-yellow-500 rounded-l-lg flex items-center p-2"><IoAlarm size={24} className="mr-2" />{t('expirationpending')}</div>
                                :
                                <span>{(CalcularExpiracion(venta.expirationdate) === "Caducado" && venta.state !== "locked") ?
                                  <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><IoAlarm size={24} className="mr-2" />{CalcularExpiracion(venta.expirationdate)}</div>
                                  :
                                  <div>{(CalcularExpiracion(venta.expirationdate).substring(0, 2).trim().length === 1 && Number(CalcularExpiracion(venta.expirationdate).substring(0, 1)) >= 0 &&
                                    Number(CalcularExpiracion(venta.expirationdate).substring(0, 1)) < 3) ?
                                    <div class="text-center text-orange-950 dark:text-orange-50 bg-gradient-to-r from-orange-400 dark:from-orange-500 rounded-l-lg flex items-center p-2"><IoAlarm size={24} className="mr-2" />{CalcularExpiracion(venta.expirationdate)}</div>
                                    :
                                    (venta.state === 'rejected' || venta.state === 'locked') ?
                                      <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><IoAlarm size={24} className="mr-2" />{CalcularExpiracion(venta.expirationdate)}</div>
                                      :
                                      <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2"><IoAlarm size={24} className="mr-2" />{moment(venta.expirationdate).format("dddd, DD [de] MMM [de] yyyy")}</div>
                                  }</div>
                                }</span>
                              }
                            </div>
                          </div>
                        </div>

                        <div className="w-full md:w-full lg:w-3/12 grid">
                          <div className="flex items-center justify-center uppercase text-sm">{t('servicetype')}</div>
                          <div className="flex items-center justify-center">
                            <div className="flex inline-flex items-center">
                              {venta.type === '1 pantalla' ?
                                <div class="text-center text-indigo-950 dark:text-indigo-50 bg-gradient-to-r from-indigo-400 dark:from-indigo-500 rounded-l-lg flex items-center p-2"><FaMobileScreen size={24} className="mr-2" />{venta.type}</div>
                                :
                                <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2"><FaScreenpal size={24} className="mr-2" />{venta.type}</div>
                              }
                            </div>
                          </div>
                        </div>

                        <div className="w-full md:w-full lg:w-3/12 grid">
                          <div className="flex items-center justify-center uppercase text-sm">{t('status')}</div>
                          <div className="flex items-center justify-center">
                            {
                              venta.suspended ?
                                <div class="text-center text-orange-950 dark:text-orange-50 bg-gradient-to-r from-orange-400 dark:from-orange-500 rounded-l-lg flex items-center p-2"><FaUserClock size={24} className="mr-2" />{t('suspended')}</div>
                                :
                                <>
                                  {venta.state === "complete" && <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2"><FaSquareCheck size={24} className="mr-2" />{t('completed')}</div>}
                                  {venta.state === "pending" && <div class="text-center text-yellow-950 dark:text-yellow-50 bg-gradient-to-r from-yellow-400 dark:from-yellow-500 rounded-l-lg flex items-center p-2"><FaShippingFast size={24} className="mr-2" />{t('pending')}</div>}
                                  {venta.state === "rejected" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><IoBan size={24} className="mr-2" />{t('rejected')}</div>}
                                  {venta.state === "locked" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><IoLockClosed size={24} className="mr-2" />{t('locked')}</div>}
                                </>
                            }
                          </div>
                          {venta.observations !== "" &&
                            <span className='text-xs text-center'>
                              {venta.observations}
                            </span>
                          }
                        </div>

                      </div>

                      <div className="flex items-center gap-2 justify-end w-full md:w-full lg:w-[19.66%] mt-4 md:mt-4 lg:mt-0">
                        <div className="mr-2 block">
                          <Tooltip title={t('viewdetails')} arrow={true}>
                            <Button
                              startIcon={<IoEye />}
                              sx={{
                                "&.Mui-disabled": {
                                  background: "#525252",
                                  color: "#a3a3a3"
                                }, "&:hover": {
                                  background: "#1e3a8a"
                                }, background: '#1d4ed8'
                              }}

                              onClick={() => HandleOpenDetalles(venta)} variant="contained" color="primary" disabled={(venta.state === "rejected" || venta.state === "pending" || venta.state === "locked" || venta.suspended) ? true : false}>
                              {t('details')}
                            </Button>
                          </Tooltip>
                        </div>

                        {
                          venta.subcategory.renovable ?
                            <>
                              <div className="mr-2 block">
                                <Tooltip title="renovar" arrow={true}>
                                  <Button
                                    startIcon={<IoReloadCircleSharp />}
                                    sx={{
                                      "&.Mui-disabled": {
                                        background: "#525252",
                                        color: "#a3a3a3"
                                      }, "&:hover": {
                                        background: "#166534"
                                      }, background: '#15803d'
                                    }}

                                    onClick={() => HandleOpenRenew(venta)} variant="contained" color="success" disabled={(venta.state === "rejected" || venta.state === "pending" || venta.state === "locked" || venta.suspended) ? true : false}>
                                    {t('renew')}
                                  </Button>
                                </Tooltip>
                              </div>
                            </>
                            :
                            <>
                              <div className="mr-2 block">
                                <Tooltip title={t('thisservicenorenew')} arrow={true}>
                                  <Button
                                    startIcon={<IoReloadCircleSharp />}
                                    sx={{
                                      "&.Mui-disabled": {
                                        background: "#525252",
                                        color: "#a3a3a3"
                                      }, "&:hover": {
                                        background: "#166534"
                                      }, background: '#15803d'
                                    }}
                                    variant="contained" color="success" disabled={venta.subcategory.renovable ? false : true}>
                                    {t('noavailable')}
                                  </Button>
                                </Tooltip>
                              </div>

                            </>
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>) : (<div className="alert-danger font-semibold mt-[285px]">{t('nodatasearchorders')}</div>) : (<SpinnerData />)
        }

      </div>

    </AccountList >
  );
};

export default UserOrders;