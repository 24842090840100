import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Spinner from "../Spinner";
import { MdOutlinePassword } from 'react-icons/md';
import { Button, IconButton, TextField } from "@mui/material";
import { message } from "../../utils/Funciones.js";
import { IoEye, IoEyeOff, IoRefreshSharp } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import Nav from "./Nav";
import { SET_TO_DEFAULT_RESPONSE, updateForgotPasswordRequest } from "../../store/features/slices/userSlice";

const ForgotPasswordReset = ({ }) => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { key } = useParams();
   const { isMutation } = useSelector((state) => state.userReducer);
   const [newPassword, SetNewPassword] = useState({ newpassword: '', repeatnewpassword: '' })
   const [verPassword, SetVerPassword] = useState({ newpassword: false, repeatnewpassword: false, })

   useEffect(() => {
      if (isMutation.updated) {
         message(isMutation.message, 'success', 5)
         dispatch(SET_TO_DEFAULT_RESPONSE())
         navigate('/login')
      }
   }, [isMutation.updated])

   const SubmitHandleChangePassword = (e) => {
      e.preventDefault()
      if (newPassword.newpassword.length < 1) {
         message('Nueva contraseña es requerido.', 'error', 4)
      } else if (newPassword.repeatnewpassword.length < 1) {
         message('Repetir nueva contraseña es requerido.', 'error', 4)
      } else if (newPassword.newpassword.length < 6 || newPassword.repeatnewpassword.length < 6) {
         message('La contraseña debe tener mínimo 6 caracteres.', 'error', 4)
      } else if (newPassword.newpassword !== newPassword.repeatnewpassword) {
         message('Las contraseñas no coinciden.', 'error', 4)
      } else {
         dispatch(updateForgotPasswordRequest({ key: key, newpassword: newPassword.newpassword }))
      }
   }

   return (
      <div className="w-full bg-brand-100 dark:bg-brand-950 h-screen">
         <Nav />
         <div className="w-full md:w-10/12 m-auto p-4 relative">
            {/* HEADER PAGES */}
            <div className="fixed p-4 flex flex-wrap text-brand-900 dark:text-brand-100 uppercase w-[calc(100%-30px)] font-semibold md:w-[calc(100%-80px)] lg:w-[calc(100%-320px)] bg-gradient-to-r from-white dark:from-brand-900 backdrop-blur-lg z-30 rounded-xl">
               <div className="w-full h-14 text-lg md:text-2xl flex items-center">
                  <div className="flex items-center w-full">
                     <MdOutlinePassword size={65} style={{ marginRight: 10 }} />
                     Restablecer mi contraseña
                  </div>
               </div>
            </div>

            <div className="w-full mt-28 md:w-12/12 flex justify-center">
               <form className="w-full md:w-4/12 flex flex-wrap" onSubmit={SubmitHandleChangePassword}>
                  <div className="w-full md:w-12/12 p-3">
                     <TextField
                        name="newpassword"
                        className="bg-brand-700 dark:bg-brand-700 rounded-lg"
                        style={{
                           width: '100%',
                           marginTop: 10, marginBottom: 10, color: '#c2c2c2',
                           borderRadius: 5
                        }}
                        sid="outlined-basic" label="Nueva contraseña" variant="outlined"
                        autoComplete="off"
                        color='primary'
                        inputProps={{
                           style: {
                              color: '#c2c2c2',
                              fontFamily: "Jost, sans-serif",
                              fontSize: 16
                           }
                        }}
                        InputProps={{
                           endAdornment:
                              <>{<IconButton color='primary' onClick={() => SetVerPassword({ ...verPassword, newpassword: !verPassword.newpassword })}>
                                 {verPassword.newpassword ? <IoEyeOff color='#c2c2c2' /> : <IoEye color='#c2c2c2' />}
                              </IconButton>}</>
                        }}
                        InputLabelProps={{
                           style: {
                              color: '#c2c2c2', fontSize: 17, paddingBottom: 5,
                              fontFamily: "Jost, sans-serif"
                           }, shrink: true,
                           inputProps: { min: "0", max: "200", step: "1" }
                        }}
                        type={verPassword.newpassword ? 'text' : 'password'}
                        value={newPassword.newpassword}
                        onChange={(e) => SetNewPassword({ ...newPassword, newpassword: e.target.value })}
                     />
                  </div>
                  <div className="w-full md:w-12/12 p-3">
                     <TextField
                        name="repeatnewpassword"
                        className="bg-brand-700 dark:bg-brand-700 rounded-lg"
                        style={{
                           width: '100%',
                           marginTop: 10, marginBottom: 10, color: '#c2c2c2',
                           borderRadius: 5
                        }}
                        sid="outlined-basic" label="Repetir nueva contraseña" variant="outlined"
                        autoComplete="off"
                        color='primary'
                        inputProps={{
                           style: {
                              color: '#c2c2c2',
                              fontFamily: "Jost, sans-serif",
                              fontSize: 16
                           }
                        }}
                        InputProps={{
                           endAdornment:
                              <>{<IconButton color='primary' onClick={() => SetVerPassword({ ...verPassword, repeatnewpassword: !verPassword.repeatnewpassword })}>
                                 {verPassword.repeatnewpassword ? <IoEyeOff color='#c2c2c2' /> : <IoEye color='#c2c2c2' />}
                              </IconButton>}</>
                        }}
                        InputLabelProps={{
                           style: {
                              color: '#c2c2c2', fontSize: 17, paddingBottom: 5,
                              fontFamily: "Jost, sans-serif"
                           }, shrink: true,
                           inputProps: { min: "0", max: "200", step: "1" }
                        }}
                        type={verPassword.repeatnewpassword ? 'text' : 'password'}
                        value={newPassword.repeatnewpassword}
                        onChange={(e) => SetNewPassword({ ...newPassword, repeatnewpassword: e.target.value })}
                     />
                  </div>

                  <div className="w-full p-3 mt-1">
                     <Button
                        sx={{
                           "&.Mui-disabled": {
                              background: "#525252",
                              color: "#a3a3a3"
                           }
                        }}
                        disabled={isMutation.uloading ? true : false}
                        type="submit" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                        size='large' variant="contained" color="success" startIcon={isMutation.uloading ? <Spinner w={'35px'} h={'35px'} /> : <IoRefreshSharp size={24} />}>{isMutation.uloading ? 'Guardando...' : 'Cambiar Contraseña'}
                     </Button>
                  </div>
               </form>
            </div>
         </div>
      </div >
   )
}
export default ForgotPasswordReset;