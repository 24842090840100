import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Spinner from "../../components/Spinner";
import { MdAlternateEmail } from 'react-icons/md';
import { Button, IconButton, Pagination, PaginationItem, Stack, TextField, Tooltip } from "@mui/material";
import { currencyFormat, message, setUnixToTimeFormat } from "../../utils/Funciones.js";
import Swal from 'sweetalert2'
import { IoCloseSharp } from "react-icons/io5";
import Wrapper from "./Wrapper";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FcPlus } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import debounce from 'lodash.debounce';
import SpinnerData from "../../components/SpinnerData";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import Spinner2 from "../../components/Spinner2";
import { FaCheckSquare, FaMinusCircle, FaPlus, FaTrashAlt, FaUser } from "react-icons/fa";
import { Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { FaBan, FaCalendarCheck, FaClipboard, FaGift, FaKey, FaMoneyBillTransfer, FaRegCalendarPlus, FaRegUser } from "react-icons/fa6";
import { del, getBypage, contador as getContador, SET_TO_DEFAULT_RESPONSE } from "../../store/features/slices/giftcardSlice";
import { uriSerialized } from "../../utils/uriSerialized";
import CopyToClipboard from "react-copy-to-clipboard";
const GiftCards = ({ }) => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const [currentPage, setCurrentPage] = useState(1);
   const [searchTerm, setSearchTerm] = useState('');
   const [pedidosByState, SetPedidosByState] = useState('')
   const [limit, setLimit] = useState(30);

   const {
      giftcardsByPage,
      isMutation,
      contador,
      totalFilter,
      total,
      totalPages
   } = useSelector((state) => state.giftcardReducer);

   useEffect(() => {
      if (isMutation.success) {
         message(isMutation.message, 'success', 5)
         dispatch(getContador())
         dispatch(SET_TO_DEFAULT_RESPONSE())
      }
      const queryOptions = {
         page: currentPage,
         limit: limit,
         search: searchTerm.trim(),
      }
      const query = queryOptions ? "?" + uriSerialized(queryOptions) : ""
      dispatch(getBypage(query))
   }, [dispatch, searchTerm, currentPage, limit, isMutation.success]);

   useEffect(() => {
      dispatch(getContador())
   }, []);

   const alertaConfirmar = (titulo, texto, icon, callback) => {
      Swal.fire({
         title: titulo,
         html: texto,
         icon: icon,
         showCancelButton: true,
         confirmButtonText: 'Si',
         denyButtonText: 'No',
         cancelButtonText: 'Cancelar',
         confirmButtonColor: '#2C3E50',
         cancelButtonColor: '#CB4335'
      }).then((result) => {
         if (result.isConfirmed) {
            callback(result.isConfirmed)
         }
      })
   }

   const deleteGiftCard = id => {
      alertaConfirmar('eliminar tarjeta de relgao', 'Estas seguro que quieres eliminar esta trajeta de regalo? recuerda que se eliminarán todos los datos relacionados a esta GiftCard.', 'question', (res_alert) => {
         if (res_alert === true) {
            dispatch(del(id));
         }
      })
   }

   const handleChangePage = (e, p) => {
      setCurrentPage(p);
   };

   const changeHandleSearch = (event) => {
      SetPedidosByState('')
      setSearchTerm(event.target.value)
      setCurrentPage(1);
   };

   const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 500), []);

   const HandleChangeFilterGc = async (state) => {
      setSearchTerm(state)
      SetPedidosByState(state)
      setCurrentPage(1);
   }

   const onChangeSearchTermClear = e => {
      setSearchTerm("");
      HandleChangeFilterGc("")
      document.getElementById("search").value = "";
   };

   const onCopy = (text) => {
      message(text, 'success', 2)
   }

   return (
      <Wrapper>

         {/* HEADER PAGES */}
         <div className="fixed pl-3 md:pl-3 lg:pl-3 h-8 top-11 z-30 flex items-center w-8/12 text-brand-900 dark:text-brand-100 text-[1.2rem] md:text-[1.8rem] lg:text-[2rem] font-semibold">
            Tarjetas de regalo
            <div class="flex items-center justify-center px-3 ml-3 text-xl font-medium bg-brand-300 dark:bg-brand-800 text-brand-800 dark:text-brand-200 rounded-full">
               {!contador.loading && contador.total}
            </div>
         </div>
         {/* HEADER PAGES */}
         <div className="fixed mt-8 p-4 flex items-center text-brand-900 dark:text-brand-100 uppercase w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] font-semibold bg-white dark:bg-brand-900 backdrop-blur-lg z-20 rounded-b-xl">
            <div className="!w-9/12">
               <InputGroup className="w-full md:w-[450px] md:max-w-[450px]">
                  <InputLeftElement pointerEvents="none" className="!mt-[14px] !ml-2">
                     <FiSearch size={24} className="text-brand-900 dark:text-brand-100" />
                  </InputLeftElement>
                  <Input size='lg' autoFocus id="search" variant='flushed' onChange={debouncedChangeHandler} type="text" placeholder={`Buscar tarjetas de regalo...`} className="flex w-full md:w-[450px] md:max-w-[450px] !h-14 !pl-10 !items-center !justify-center !border-2 rounded-xl !bg-brand-200 
            dark:!bg-brand-950 !text-sm focus:!ring-lightPrimary dark:focus:!ring-darkPrimary focus:!border-lightPrimary dark:focus:!border-darkPrimary 
            !outline-none !border-brand-300 dark:!border-brand-800 !text-brand-900 dark:!text-white !relative !placeholder-brand-500" />
                  <InputRightElement className="!mt-[8px] !mr-1">
                     <IconButton onClick={onChangeSearchTermClear} className={`mr-2 ${searchTerm.trim().length > 0 ? 'scale-100' : 'scale-0'} transition-all duration-500 text-brand-600 dark:text-brand-300`}>
                        <IoCloseSharp size={20} className="text-brand-900 dark:text-brand-100" />
                     </IconButton>
                  </InputRightElement>
               </InputGroup>
            </div>
            <div className="!w-3/12 flex items-center justify-end">
               <div className="flex items-center justify-end">
                  <Link to="/admin/create-giftcard" className="flex justify-end">
                     <Tooltip title="registrar tarjeta de regalo" arrow={true}>
                        <Button type="button" className="!bg-green-500" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }}>
                           <FaPlus size={45} className="!text-white" />
                        </Button>
                     </Tooltip>
                  </Link>
               </div>
            </div>
         </div>

         <div className="w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] mb-0 mt-[102px] pb-3 pt-5 md:pt-5 lg:pt-10 fixed z-20 bg-white/10 dark:bg-[#00000023] backdrop-blur-lg flex flex-col md:flex-col lg:flex-row items-center">
            <div className="order-1 md:order-1 lg:order-2 w-full md:w-full lg:w-6/12 flex items-center justify-center md:justify-center lg:justify-end text-brand-200 mb-6 md:mb-6 lg:mb-0">
               {
                  !isMutation.loading ?
                     <>
                        <div className="hidden md:block">
                           <Stack spacing={2}>
                              <Pagination
                                 count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                                 page={currentPage}
                                 onChange={handleChangePage}
                                 variant="text"
                                 color="primary"
                                 shape="rounded"
                                 size="large"
                                 renderItem={(item) => (
                                    <PaginationItem
                                       slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                       {...item}
                                    />
                                 )}
                                 className="bg-brand-200 dark:bg-brand-950"
                              />
                           </Stack>
                        </div>

                        <div className="block md:hidden">
                           <Stack spacing={2}>
                              <Pagination
                                 count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                                 page={currentPage}
                                 onChange={handleChangePage}
                                 variant="text"
                                 color="primary"
                                 shape="rounded"
                                 size="small"
                                 renderItem={(item) => (
                                    <PaginationItem
                                       slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                       {...item}
                                    />
                                 )}
                                 className="bg-brand-200 dark:bg-brand-950"
                              />
                           </Stack>
                        </div>
                     </> : <Spinner />
               }
            </div>

            <div className="order-2 md:order-2 lg:order-1 w-full md:w-full lg:w-6/12">
               <Tooltip title="Click aquí para filtrar tarjetas de regalo disponibles" arrow={true}>
                  <div onClick={() => HandleChangeFilterGc("Free")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "Free" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-green-600 p-2 md:p-3 rounded-lg`}>
                     <div className="flex inline-flex items-center"><FaCheckSquare className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                     <div className="-mt-12 -mr-4">
                        <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                           <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600"></span>
                           <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                              {!contador.loading ? contador.free : <Spinner2 />}
                           </span>
                        </span>
                     </div>
                  </div>
               </Tooltip>
               <Tooltip title="Click aquí para filtrar tarjetas de regalo usadas" arrow={true}>
                  <div onClick={() => HandleChangeFilterGc("Used")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "Used" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-red-600 p-2 md:p-3 rounded-lg ml-3 md:ml-5`}>
                     <div className="flex inline-flex items-center"><FaBan className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                     <div className="-mt-12 -mr-4">
                        <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                           <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-600"></span>
                           <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                              {!contador.loading ? contador.used : <Spinner2 />}
                           </span>
                        </span>
                     </div>
                  </div>
               </Tooltip>
               <Tooltip title="Click aquí para filtrar todos las tarjetas de regalo" arrow={true}>
                  <div onClick={() => HandleChangeFilterGc("")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-green-500 p-2 md:p-3 rounded-lg ml-3 md:ml-5`}>
                     <div className="flex inline-flex items-center"><FaGift className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                     <div className="-mt-12 -mr-4">
                        <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                           <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500"></span>
                           <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                              {!contador.loading ? contador.total : <Spinner2 />}
                           </span>
                        </span>
                     </div>
                  </div>
               </Tooltip>
            </div>
         </div>

         <div className="relative w-full">
            {!isMutation.loading ? giftcardsByPage.length > 0 ?
               <div className="flex flex-wrap mt-[300px] md:mt-[325px] lg:mt-[280px]">
                  {giftcardsByPage.map((giftcard) => {

                     return (
                        <div className={`relative p-4 w-full shadow-xl shadow-neutral-200 dark:shadow-none cursor-pointer md:w-12/12 bg-white dark:bg-brand-900 text-brand-900 dark:text-brand-200 flex flex-wrap mb-12 rounded-lg animation`} key={giftcard._id}>
                           <label className="absolute -top-8 left-0 p-2 flex items-center text-brand-800 dark:text-brand-200 text-base rounded-tl-lg rounded-tr-lg bg-white dark:bg-brand-900">
                              <span className="text-[12px]">{giftcard.ventaId ? giftcard.ventaId : 'sin datos'}</span>
                           </label>
                           <div className="flex flex-wrap items-center w-full md:w-full lg:w-3/12">
                              <div className="flex inline-flex items-center">
                                 <LazyLoadImage
                                    className={`object-cover max-h-[50px] min-h-[50px] max-w-[50px] min-w-[50px] z-10 rounded-full flex inline-flex`}
                                    alt={`No image ${giftcard.subcategory?.name}`}
                                    effect="opacity"
                                    src={`/images/logos/${giftcard.subcategory?.logo}`}
                                 />
                                 <div className="grid self-center gap-1">
                                    <div className="ml-2 text-sm font-semibold truncate ...">{giftcard.subcategory?.name}</div>
                                    <div className="ml-2 flex items-center text-xs truncate ..."><MdAlternateEmail size={16} className='mr-[5px]' />  {giftcard.user?.email ? giftcard.user?.email : 'sin datos'}
                                       {giftcard.user?.email &&
                                          <span className="ml-3">
                                             <Tooltip title="copiar" arrow={true}>
                                                <div>
                                                   <CopyToClipboard text={giftcard.user.email} onCopy={() => onCopy('correo copiado')}>
                                                      <FaClipboard size={20} className="text-brand-900 dark:text-brand-200 cursor-pointer w-[35px]" />
                                                   </CopyToClipboard>
                                                </div>
                                             </Tooltip>
                                          </span>
                                       }
                                    </div>
                                    <div className="ml-2 flex items-center text-xs truncate ..."><FaRegUser size={16} className='mr-[5px]' /> {giftcard.user?.name ? giftcard.user?.name : 'sin datos'}</div>
                                    <div className="ml-2 flex items-center text-xs mt-1 truncate ..."><FaRegCalendarPlus size={16} className='mr-[5px]' />{setUnixToTimeFormat('created_at', giftcard.created_at)}</div>
                                 </div>
                              </div>
                           </div>

                           <div className="flex w-full md:w-full lg:w-[calc(75%-45px)] flex-wrap">
                              <div className="w-full md:w-6/12 lg:w-3/12 grid">
                                 <div className="flex items-center justify-center uppercase text-sm">Código</div>
                                 <div className="flex items-center justify-center">
                                    <div className="flex inline-flex items-center justify-center">
                                       <div class="w-full text-center text-indigo-950 dark:text-indigo-50 bg-gradient-to-r from-indigo-400 dark:from-indigo-500 rounded-l-lg flex items-center p-2">
                                          <FaKey size={24} className="mr-2 w-[35px] min-w-[35px]" />
                                          <div className="w-[calc(100%-60px)] text-base">{(giftcard.code).substr(0, giftcard.code.length - 4)}...</div>
                                          <div className="ml-3 w-[30px] flex justify-end">
                                             <Tooltip title="copiar" arrow={true}>
                                                <div>
                                                   <CopyToClipboard text={giftcard.code} onCopy={() => onCopy('código copiado')}>
                                                      <FaClipboard size={20} className="text-brand-900 dark:text-brand-200 cursor-pointer w-[35px]" />
                                                   </CopyToClipboard>
                                                </div>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div className="w-full md:w-6/12 lg:w-4/12 grid">
                                 <div className="flex items-center justify-center uppercase text-sm">Fecha de venta</div>
                                 <div className="flex items-center justify-center">
                                    <div className="flex inline-flex items-center">
                                       <div class="text-center text-blue-950 dark:text-blue-50 bg-gradient-to-r from-blue-400 dark:from-blue-500 rounded-l-lg flex items-center p-2"><FaCalendarCheck size={24} className="mr-2" /> {giftcard.selldate !== null ? setUnixToTimeFormat('selldate', giftcard.selldate) : 'Sin datos'}</div>
                                    </div>
                                 </div>
                              </div>

                              <div className="w-full md:w-6/12 lg:w-2/12 grid">
                                 <div className="flex items-center justify-center uppercase text-sm">precio de venta</div>
                                 <div className="flex items-center justify-center">
                                    <div className="flex inline-flex items-center">
                                       <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2"><FaMoneyBillTransfer size={24} className="mr-2" />{giftcard.sellprice > 0 ? currencyFormat(giftcard.sellprice) : 'Sin datos'}</div>
                                    </div>
                                 </div>
                              </div>

                              <div className="w-full md:w-6/12 lg:w-3/12 grid">
                                 <div className="flex items-center justify-center uppercase text-sm">estado</div>
                                 <div className="flex items-center justify-center">
                                    <div className="flex inline-flex items-center">
                                       {
                                          giftcard.isused ?
                                             <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><FaMinusCircle size={24} className="mr-2" />Vendido</div>
                                             :
                                             <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2"><FaCheckSquare size={24} className="mr-2" />Disponible</div>
                                       }
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className="flex items-center gap-2 justify-end w-full md:w-full lg:md:w-[45px] mt-5 md:mt-5 lg:mt-0">
                              <Tooltip title="eliminar" arrow={true}>
                                 <Button onClick={() => deleteGiftCard(giftcard._id)} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                                    <FaTrashAlt className="w-5 h-5 text-white" />
                                 </Button>
                              </Tooltip>
                           </div>
                        </div>
                     )
                  })}
               </div> : <div className="alert-danger font-semibold mt-[300px] md:mt-[325px] lg:mt-[280px]">Sin resultados en la búsqueda! o no existen tarjetas de regalo registradas</div> : <div className="mt-[300px] md:mt-[325px] lg:mt-[280px]"><SpinnerData>Cargando pedidos, por favor espere...</SpinnerData></div>}
         </div>
      </Wrapper>
   )
}
export default GiftCards;