import { useEffect, useState } from "react"
import Spinner from "../../components/Spinner";
import { MdCategory, MdDelete, MdDevices, MdEdit, MdLocalOffer, MdPhoneIphone, MdTimer } from "react-icons/md";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import { IoAt, IoChevronForward, IoCloseSharp, IoEye, IoPodium, IoSave, IoSearch, IoShareSocial, IoTime } from "react-icons/io5";
import { convertirsegundos, currencyFormat, message, setUnixToTimeFormat } from "../../utils/Funciones.js";
import Swal from 'sweetalert2'
import { FaCertificate, FaCogs, FaCoins, FaDollarSign, FaEdit, FaPencilAlt, FaPlus, FaShippingFast, FaTrashAlt, FaUser, FaUserTie } from "react-icons/fa";
import { SiNano } from "react-icons/si";
import Wrapper from "./Wrapper";
import { FcBullish, FcBusinessman, FcClock, FcDebt, FcFlashAuto, FcMoneyTransfer, FcPlus, FcRating, FcReading, FcServices } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import SpinnerData from "../../components/SpinnerData";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import ImagesPreview from "../../components/ImagesPreview";
import ImagesPreview2 from "../../components/ImagesPreview2";
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import UpdateSubCategorySocial from "./UpdateSubCategorySocial";
import ImagesPreviewBanner from "../../components/ImagesPreviewBanner";
import { useDispatch, useSelector } from "react-redux";
import { Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { FaBoltLightning, FaEye, FaRegCalendarPlus, FaUserClock, FaUserTag } from "react-icons/fa6";
import Modal from "../../components/modal/Modal";
import { SET_TO_DEFAULT_RESPONSE, updatePlataform as onUpdatePlataform } from "../../store/features/slices/socialSlice";
import Switch from "../../components/switch";

const SocialPlataforms = ({ }) => {

   const dispatch = useDispatch();
   const navigate = useNavigate();

   const [searchSocialPlataforms, SetSearchSocialPlataforms] = useState("")
   const [updatePlataform, setUpdatePlataform] = useState({ _id: "", nameplataform: "", enabled: false, description: "", open: false, currentlogo: "", currentsublogo: "", currentbanner: "", logo: "", sublogo: "", banner: "" });
   const [openVerSubcategorias, SetOpenVerSubcategorias] = useState({ open: false, plataformIsUsed: false, name: "", plataformId: "", sublogo: "" })
   const [openeditsubcategory, SetOpenEditSubCategory] = useState({ open: false, plataformId: "", serviceData: null })
   const [enabled, SetEnabled] = useState(true)
   const [preview, setPreview] = useState({
      logo: '',
      sublogo: '',
      banner: ''
   })

   const [editorState, setEditorState] = useState(
      () => EditorState.createEmpty(),
   );

   const {
      plataforms,
      services,
      isMutation
   } = useSelector((state) => state.socialReducer);

   useEffect(() => {
      if (isMutation.updated) {
         message(isMutation.message, 'success', 5)
         updatePlataform.open && onCloseupdatePlataform()
         openeditsubcategory.open && HandleCloseEditSubCategorySocial()
         dispatch(SET_TO_DEFAULT_RESPONSE())
      }
   }, [isMutation.updated])

   const imageHandle = e => {
      if (e.target.files.length !== 0) {
         setUpdatePlataform({ ...updatePlataform, [e.target.name]: e.target.files[0] });
         const reader = new FileReader();
         reader.onloadend = () => {
            setPreview({ ...preview, [e.target.name]: reader.result })
         }
         reader.readAsDataURL(e.target.files[0]);
      }
   }


   const alertaConfirmar = (titulo, texto, icon, callback) => {
      Swal.fire({
         title: titulo,
         html: texto,
         icon: icon,
         showCancelButton: true,
         confirmButtonText: 'Si',
         denyButtonText: 'No',
         cancelButtonText: 'Cancelar',
         confirmButtonColor: '#2C3E50',
         cancelButtonColor: '#CB4335'
      }).then((result) => {
         if (result.isConfirmed) {
            callback(result.isConfirmed)
         }
      })
   }

   const HandleFilterCategories = !isMutation.loading && plataforms.filter(_c => {
      return _c.name.toLowerCase().includes(searchSocialPlataforms.toLowerCase())

   })

   const onOpenUpdatePlataform = (plataform) => {
      setUpdatePlataform({ ...updatePlataform, description: plataform.description, enabled: plataform.enabled, nameplataform: plataform.name, _id: plataform._id, open: true, currentlogo: plataform.logo, currentsublogo: plataform.sublogo, currentbanner: plataform.banner })
      SetEnabled(plataform.enabled)
      const rawContent = plataform.description;
      const contentState = convertFromRaw(JSON.parse(rawContent));
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState)
   }

   const onCloseupdatePlataform = () => {
      setUpdatePlataform({ ...updatePlataform, _id: "", enabled: false, nameplataform: "", description: "", open: false, currentlogo: "", currentsublogo: "", currentbanner: "", logo: "", sublogo: "", banner: "" })
      setPreview({ ...preview, logo: "", sublogo: "", banner: "" })
   }

   const updateSocialPlataform = () => {
      const contentState = editorState.getCurrentContent();
      if (contentState.hasText()) {
         if (updatePlataform.nameplataform.trim().length < 1) {
            message('nombre de plataform es requerido', 'error', 4)
         } else {
            const formData = new FormData();
            formData.append('data', JSON.stringify(updatePlataform));
            formData.append('rawData', JSON.stringify(convertToRaw(contentState)));
            formData.append('image1', updatePlataform.logo)
            formData.append('image2', updatePlataform.sublogo)
            formData.append('image3', updatePlataform.banner)
            dispatch(onUpdatePlataform(formData));
         }
      } else {
         message('Descripción y modo de uso es requerido', 'error', 4)
      }
   }

   const onOpenVerSMMServicios = (paltaform) => {
      SetOpenVerSubcategorias({ ...openVerSubcategorias, open: !openVerSubcategorias.open, plataformIsUsed: false, plataformId: paltaform._id, name: paltaform.name, sublogo: paltaform.sublogo })
   }

   const onCloseVerSubcategorias = () => {
      SetOpenVerSubcategorias({ ...openVerSubcategorias, plataformIsUsed: false, open: !openVerSubcategorias.open, plataformId: "", name: "", sublogo: "" })
   }

   const HandleOpenEditSubCategory = (plataformId, data) => {
      SetOpenEditSubCategory({ ...openeditsubcategory, open: true, plataformId: plataformId, serviceData: data });
   }

   const HandleCloseEditSubCategorySocial = () => {
      SetOpenEditSubCategory({ ...openeditsubcategory, open: false, plataformId: "", serviceData: null });
   }

   const handleDeleteSubCategory = (plataformId, serviceId) => {
      alertaConfirmar('eliminar Subcategoría', 'Estas seguro que quieres eliminar esta subcategoría? recuerda que se eliminarán todos los datos relacionados a esta subcategoría.', 'question', async (res_alert) => {
         if (res_alert === true) {
            //dispatch(deleteSubCategory({ categoryId: categoryId, subcategoryId: subcategoryId }))
         }
      })
   }

   const OnChangeEnabled = () => {
      SetEnabled(!enabled)
      setUpdatePlataform({ ...updatePlataform, ['enabled']: !enabled })
   }

   const onChangeSearchTermClear = e => {
      SetSearchSocialPlataforms("");
      document.getElementById("search").value = "";
   };

   return (
      <Wrapper>
         {/* HEADER PAGES */}
         <div className="fixed pl-3 md:pl-3 lg:pl-3 h-8 top-11 z-30 flex items-center w-8/12 text-brand-900 dark:text-brand-100 text-[1.2rem] md:text-[1.8rem] lg:text-[2rem] font-semibold">
            Plataformas SMM
            <div class="flex items-center justify-center px-3 ml-3 text-xl font-medium bg-brand-300 dark:bg-brand-800 text-brand-800 dark:text-brand-200 rounded-full">
               {!isMutation.loading && plataforms.length}
            </div>
         </div>
         <div className="fixed mt-8 p-4 flex items-center text-brand-900 dark:text-brand-100 uppercase w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] font-semibold bg-white dark:bg-brand-900 backdrop-blur-lg z-20 rounded-b-xl">
            <div className="!w-9/12">
               <InputGroup className="w-full md:w-[450px] md:max-w-[450px]">
                  <InputLeftElement pointerEvents="none" className="!mt-[14px] !ml-2">
                     <FiSearch size={24} className="text-brand-900 dark:text-brand-100" />
                  </InputLeftElement>
                  <Input size='lg' autoFocus id="search" variant='flushed' value={searchSocialPlataforms} onChange={(e) => SetSearchSocialPlataforms(e.target.value)} type="text" placeholder={`Buscar plataformas...`} className="flex w-full md:w-[450px] md:max-w-[450px] !h-14 !pl-10 !items-center !justify-center !border-2 rounded-xl !bg-brand-200 
            dark:!bg-brand-950 !text-sm focus:!ring-lightPrimary dark:focus:!ring-darkPrimary focus:!border-lightPrimary dark:focus:!border-darkPrimary 
            !outline-none !border-brand-300 dark:!border-brand-800 !text-brand-900 dark:!text-white !relative !placeholder-brand-500" />
                  <InputRightElement className="!mt-[8px] !mr-1">
                     <IconButton onClick={onChangeSearchTermClear} className={`mr-2 ${searchSocialPlataforms.trim().length > 0 ? 'scale-100' : 'scale-0'} transition-all duration-500 text-brand-600 dark:text-brand-300`}>
                        <IoCloseSharp size={20} className="text-brand-900 dark:text-brand-100" />
                     </IconButton>
                  </InputRightElement>
               </InputGroup>
            </div>
            <div className="!w-3/12 flex items-center justify-end">
               <div className="flex items-center justify-end">
                  <Link to="/admin/create-social-service" className="flex justify-end">
                     <Tooltip title="registrar SMM servicio" arrow={true}>
                        <Button type="button" className="!bg-green-500" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }}>
                           <FaPlus size={45} className="!text-white" />
                        </Button>
                     </Tooltip>
                  </Link>
               </div>
            </div>
         </div>

         <Modal
            open={openeditsubcategory.open}
            onClose={HandleCloseEditSubCategorySocial}
            headerTitle={`Editar SMM servicio`}
         >
            <div className="flex flex-wrap h-auto">
               <UpdateSubCategorySocial
                  serviceData={openeditsubcategory.serviceData}
               />
            </div>
         </Modal>

         <Modal
            open={openVerSubcategorias.open}
            onClose={onCloseVerSubcategorias}
            headerTitle={`SMM Servicios de ${openVerSubcategorias.name}`}
         >
            <div className="flex flex-wrap h-auto">
               {
                  services.filter((fil) => fil.plataform._id === openVerSubcategorias.plataformId).length > 0 ? services.filter((fil) => fil.plataform._id === openVerSubcategorias.plataformId).sort((a, b) => a.created_at - b.created_at).map((service, index) => {

                     return (
                        <div key={index} className="relative mt-3 p-5 w-full shadow-xl shadow-neutral-200 dark:shadow-none md:w-12/12 bg-white dark:bg-brand-900 text-brand-900 dark:text-brand-200 flex flex-wrap mb-10 rounded-lg animation">
                           <div className="absolute p-2 bg-white dark:bg-brand-900 rounded-tl-xl rounded-tr-xl w-full md:w-auto text-sm left-0 -top-7 font-bold text-brand-900 dark:text-brand-200 truncate ...">{service.name}</div>

                           <div className="flex items-center w-full md:w-full lg:w-[calc(33.333%-85px)] relative">
                              <div className="w-[calc(55px)]">
                                 <LazyLoadImage
                                    className="object-cover w-[52px] h-[52px] z-24 rounded-full flex inline-flex"
                                    alt={`No image`}
                                    effect="opacity"
                                    id='prelogo'
                                    src={`/images/logos/${openVerSubcategorias.sublogo}`}
                                 />
                              </div>
                              <div className="w-[calc(100%-55px)]">
                                 <div className="ml-2 w-full text-brand-900 dark:text-brand-200 text-sm mt-2 flex items-center font-bold truncate ..."><FcServices size={26} className="mr-1" />{service.serviceId}</div>
                                 <div className="w-full grid">
                                    <div className="flex items-center ml-2">
                                       {
                                          service.enabled ?
                                             <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2">
                                                Habilitado
                                             </div>
                                             :
                                             <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2">
                                                Deshabilitado
                                             </div>
                                       }

                                    </div>
                                 </div>
                                 <div className="ml-2 w-full text-brand-900 dark:text-brand-200 text-xs mt-2 truncate ...">{setUnixToTimeFormat('created_at', service.created_at)}</div>
                              </div>
                           </div>

                           <div className="w-full md:w-6/12 lg:w-2/12 grid mt-3 md:mt-3 lg:mt-0">
                              <div className="flex items-center justify-center text-sm uppercase">Precios fijos</div>
                              <div className="flex items-center justify-center">
                                 <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2">
                                    <div className="flex items-center mr-3"><FaUserTag size={24} className="mr-1" />{currencyFormat(service.preciofijouser)}</div>
                                    <div className="flex items-center"><FaCoins size={24} className="mr-1" />{currencyFormat(service.preciofijoreseller)}</div>
                                 </div>
                              </div>
                           </div>

                           <div className="w-full md:w-6/12 lg:w-2/12 grid mt-3 md:mt-3 lg:mt-0">
                              <div className="flex items-center justify-center text-sm uppercase">% adicional aplicado</div>
                              <div className="flex items-center justify-center">
                                 <div class="text-center text-blue-950 dark:text-blue-50 bg-gradient-to-r from-blue-400 dark:from-blue-500 rounded-l-lg flex items-center p-2">
                                    <MdLocalOffer size={24} className="mr-1" />+ {service.porcentajeadicional}%
                                 </div>
                              </div>
                           </div>

                           <div className="w-full md:w-6/12 lg:w-2/12 grid mt-3 md:mt-3 lg:mt-0">
                              <div className="flex items-center justify-center text-sm uppercase">Tiempo estimado</div>
                              <div className="flex items-center justify-center">
                                 <div class="text-center text-indigo-950 dark:text-indigo-50 bg-gradient-to-r from-indigo-400 dark:from-indigo-500 rounded-l-lg flex items-center p-2">
                                    <MdTimer size={24} className="mr-1" />{convertirsegundos(service.speedseconds)}
                                 </div>
                              </div>
                           </div>

                           <div className="w-full md:w-6/12 lg:w-2/12 grid mt-3 md:mt-3 lg:mt-0">
                              <div className="flex items-center justify-center text-sm uppercase">Tipo de entrega</div>
                              <div className="flex items-center justify-center">
                                 {service.tipoentrega ?
                                    <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2">
                                       <FaBoltLightning size={24} className="mr-1" />Automática
                                    </div>
                                    :
                                    <div class="text-center text-cyan-950 dark:text-cyan-50 bg-gradient-to-r from-cyan-400 dark:from-cyan-500 rounded-l-lg flex items-center p-2">
                                       <FaCogs size={24} className="mr-1" />Manual
                                    </div>
                                 }
                              </div>
                           </div>

                           <div className="flex items-center gap-2 justify-end w-full md:w-full lg:w-[85px] mt-5 md:mt-5 lg:mt-0">
                              <Tooltip title="editar" arrow={true}>
                                 <Button onClick={() => HandleOpenEditSubCategory(openVerSubcategorias.plataformId, service)} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-amber-500 !px-2 !py-2 !text-base font-medium !text-white transition !duration-200 hover:!bg-amber-600 active:!bg-amber-700 dark:!bg-amber-400 dark:!text-white dark:hover:!bg-amber-300 dark:active:!bg-amber-200">
                                    <FaPencilAlt className="w-5 h-5 text-white" />
                                 </Button>
                              </Tooltip>

                              <Tooltip title="eliminar" arrow={true}>
                                 <Button onClick={() => handleDeleteSubCategory(openVerSubcategorias.plataformId, service._id)} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                                    <FaTrashAlt className="w-5 h-5 text-white" />
                                 </Button>
                              </Tooltip>
                           </div>
                        </div>)
                  }) :
                     <div class="flex items-center m-auto p-3 mb-3 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-brand-800 dark:text-yellow-500" role="alert">
                        <svg class="flex-shrink-0 inline w-8 h-8 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                           <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <span class="sr-only">Info</span>
                        <div>
                           <span class="font-bold uppercase">Sin datos!</span> La plataforma <b>{openVerSubcategorias.name}</b> aún no tiene datos registrados!
                        </div>
                     </div>
               }
            </div>
         </Modal>

         <Modal
            open={updatePlataform.open}
            onClose={onCloseupdatePlataform}
            headerTitle="Editar plataforma"
         >
            <div className="flex flex-wrap h-auto">
               <div className="w-full flex flex-wrap">
                  <div className="w-full mb-5">
                     <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Nombre de la plataforma</label>
                     <input autoFocus name="nameplataform" className="custom-style-input"
                        autoComplete="off"
                        required
                        value={updatePlataform.nameplataform}
                        type="text"
                        onChange={(e) => setUpdatePlataform({ ...updatePlataform, nameplataform: e.target.value })}
                     />
                  </div>

                  <div class="w-full md:w-6/12">

                     <label htmlFor="logo" class="flex flex-col items-center justify-center w-full h-[250px] border-2 border-brand-500 border-dashed rounded-lg cursor-pointer bg-brand-100 dark:bg-brand-900 hover:bg-brand-200 dark:hover:bg-brand-800">
                        <div class="flex flex-col items-center justify-center pt-5 pb-6 text-center">
                           <svg aria-hidden="true" class="w-10 h-10 mb-3 text-brand-900 dark:text-brand-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                           <p class="mb-2 text-sm text-brand-900 dark:text-brand-200"><span class="font-semibold">Click para subir imagen logo </span> o arrastrar y soltar</p>
                           <p class="text-xs text-brand-900 dark:text-brand-200">PNG o JPG (MAX. 800x400px)</p>
                        </div>
                        <input id="logo" accept="image/png,image/jpeg,image/webp" type="file" name="logo" class="hidden" onChange={imageHandle} />

                     </label>

                  </div>
                  <div className="border-2 w-full md:w-6/12 h-[250px] rounded-lg border-brand-500 border-dashed flex inline-flex items-center justify-center">
                     <div className="">
                        <LazyLoadImage
                           className="object-contain w-[150px] h-[220px] z-24 rounded-lg flex inline-flex"
                           alt={`No image`}
                           effect="opacity"
                           src={`/images/logos/${updatePlataform.currentlogo}`}
                        />
                     </div>
                     {preview.logo && <IoChevronForward className="text-brand-300" size={32} />}
                     <ImagesPreview2 url={preview.logo} />
                  </div>
               </div>

               <div className="w-full flex flex-wrap">
                  <div class="w-full md:w-6/12">

                     <label htmlFor="sublogo" class="flex flex-col items-center justify-center w-full h-[250px] border-2 border-brand-500 border-dashed rounded-lg cursor-pointer bg-brand-100 dark:bg-brand-900 hover:bg-brand-200 dark:hover:bg-brand-800">
                        <div class="flex flex-col items-center justify-center pt-5 pb-6 text-center">
                           <svg aria-hidden="true" class="w-10 h-10 mb-3 text-brand-900 dark:text-brand-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                           <p class="mb-2 text-sm text-brand-900 dark:text-brand-200"><span class="font-semibold">Click para subir imagen sublogo </span> o arrastrar y soltar</p>
                           <p class="text-xs text-brand-900 dark:text-brand-200">PNG o JPG (MAX. 800x400px)</p>
                        </div>
                        <input id="sublogo" accept="image/png,image/jpeg,image/webp" type="file" name="sublogo" class="hidden" onChange={imageHandle} />

                     </label>

                  </div>
                  <div className="border-2 w-full md:w-6/12 h-[250px] rounded-lg border-brand-500 border-dashed flex inline-flex items-center justify-center">
                     <div className="">
                        <LazyLoadImage
                           className="object-cover w-[150px] h-[150px] z-24 rounded-full flex inline-flex"
                           alt={`No image`}
                           effect="opacity"
                           src={`/images/logos/${updatePlataform.currentsublogo}`}
                        />
                     </div>
                     {preview.sublogo && <IoChevronForward className="text-brand-300" size={32} />}
                     <ImagesPreview url={preview.sublogo} />
                  </div>
               </div>

               <div className="w-full flex flex-wrap">
                  <div class="w-full md:w-6/12">

                     <label htmlFor="banner" class="flex flex-col items-center justify-center w-full h-[250px] border-2 border-brand-500 border-dashed rounded-lg cursor-pointer bg-brand-100 dark:bg-brand-900 hover:bg-brand-200 dark:hover:bg-brand-800">
                        <div class="flex flex-col items-center justify-center pt-5 pb-6 text-center">
                           <svg aria-hidden="true" class="w-10 h-10 mb-3 text-brand-900 dark:text-brand-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                           <p class="mb-2 text-sm text-brand-900 dark:text-brand-200"><span class="font-semibold">Click para subir imagen banner </span> o arrastrar y soltar</p>
                           <p class="text-xs text-brand-900 dark:text-brand-200">PNG o JPG (MAX. 800x400px)</p>
                        </div>
                        <input id="banner" accept="image/png,image/jpeg,image/webp" type="file" name="banner" class="hidden" onChange={imageHandle} />

                     </label>

                  </div>
                  <div className="border-2 w-full md:w-6/12 h-[250px] rounded-lg border-brand-500 border-dashed flex inline-flex items-center justify-center">
                     <div className="">
                        <LazyLoadImage
                           className="object-cover w-[180px] h-[110px] z-24 rounded-full flex inline-flex"
                           alt={`No image`}
                           effect="opacity"
                           src={`/images/logos/${updatePlataform.currentbanner}`}
                        />
                     </div>
                     {preview.banner && <IoChevronForward className="text-brand-300" size={32} />}
                     <ImagesPreviewBanner url={preview.banner} />
                  </div>
               </div>

               <div className="w-full mb-5 mt-5">
               <label className="text-brand-900 dark:text-brand-200 text-sm">Estado del servicio <span className="font-semibold">{enabled ? "habilitado" : "deshabilitado"}</span></label>
                  <div className="h-10 mt-3">
                     <Switch
                        value={enabled}
                        onChange={OnChangeEnabled}
                     />
                  </div>
               </div>

               <div className="w-full">
                  <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Descripción y modo de uso</label>
                  <Editor
                     editorState={editorState}
                     onEditorStateChange={setEditorState}
                     placeholder="Escribe aquí..."
                  />
               </div>
               <div className="w-full mt-10">
                  <Button
                     disabled={isMutation.uloading ? true : false}
                     onClick={updateSocialPlataform}
                     sx={{
                        "&.Mui-disabled": {
                           background: "#525252",
                           color: "#a3a3a3"
                        }
                     }}
                     type="button" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                     size='large' variant="contained" color="primary">{isMutation.uloading ? <><Spinner w={'35px'} h={'35px'} /> PROCESANDO... </> : <><IoSave size={24} className="mr-2" /> ACTUALIZAR PLATAFORMA</>}</Button>
               </div>
            </div>
         </Modal>

         {!isMutation.loading ? HandleFilterCategories.length > 0 ? <>
            <div className="flex flex-wrap mt-[155px]">


               {HandleFilterCategories.map((plataform) => {

                  var plataformIsUsed = false

                  return (
                     <div className={`p-4 w-full shadow-xl shadow-neutral-200 dark:shadow-none cursor-pointer md:w-12/12 bg-white dark:bg-brand-900 text-brand-900 dark:text-brand-200 flex flex-wrap mb-10 rounded-lg animation`} key={plataform._id}>
                        <div className="grid grid-wrap w-full md:w-full lg:w-4/12">
                           <div className="flex inline-flex items-center">
                              <LazyLoadImage
                                 className="object-contain w-24 h-32 z-10 rounded-sm flex inline-flex"
                                 alt={`No image ${plataform.name}`}
                                 effect="opacity"
                                 src={`/images/logos/${plataform.logo}`}
                              />
                              <LazyLoadImage
                                 className="object-cover w-12 h-12 z-10 rounded-full flex inline-flex ml-2"
                                 alt={`No image ${plataform.name}`}
                                 effect="opacity"
                                 src={`/images/logos/${plataform.sublogo}`}
                              />
                              <div className="grid gap-1 self-center">
                                 <div className="ml-2 text-sm text-brand-900 dark:text-brand-200 font-bold uppercase">{plataform.name}</div>
                                 <div className="ml-2 flex items-center text-xs mt-2 truncate ..."><FaRegCalendarPlus size={16} className='mr-[5px] min-w-[20px] min-h-[20px]' /><label className="truncate ...">{setUnixToTimeFormat('created_at', plataform.created_at)}</label></div>
                              </div>
                           </div>
                        </div>

                        <div className="w-full md:w-full lg:w-2/12 grid mt-8 md:mt-0">
                           <div className="flex items-center justify-center text-sm">COLOR TEMA</div>
                           <div className="flex items-center justify-center">
                              <div className="flex inline-flex items-center text-2xl font-bold">
                                 <div className="w-8 h-8 rounded-full border-2 border-brand-300 dark:border-brand-700" style={{ background: `${plataform.themecolor}` }}>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="w-full md:w-full lg:w-2/12 grid mt-3 md:mt-0">
                           <div className="flex items-center justify-center text-sm">SMM SERVICIOS</div>
                           <div className="flex items-center justify-center">
                              <div className="flex inline-flex items-center text-2xl font-semibold">
                                 {services.filter((fil) => fil.plataform._id === plataform._id).length}
                              </div>
                           </div>
                        </div>

                        <div className="flex items-center gap-2 justify-end w-full md:w-full lg:w-4/12">
                           <Tooltip title="ver SMM servicios" arrow={true}>
                              <Button onClick={() => onOpenVerSMMServicios(plataform)} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-indigo-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-indigo-600 active:!bg-indigo-700 dark:!bg-indigo-400 dark:!text-white dark:hover:!bg-indigo-300 dark:active:!bg-indigo-200">
                                 <FaEye className="w-5 h-5 text-white" />
                              </Button>
                           </Tooltip>

                           <Tooltip title="editar" arrow={true}>
                              <Button onClick={() => onOpenUpdatePlataform(plataform)} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-amber-500 !px-2 !py-2 !text-base font-medium !text-white transition !duration-200 hover:!bg-amber-600 active:!bg-amber-700 dark:!bg-amber-400 dark:!text-white dark:hover:!bg-amber-300 dark:active:!bg-amber-200">
                                 <FaPencilAlt className="w-5 h-5 text-white" />
                              </Button>
                           </Tooltip>

                           <Tooltip title="eliminar" arrow={true}>
                              <Button variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                                 <FaTrashAlt className="w-5 h-5 text-white" />
                              </Button>
                           </Tooltip>
                        </div>
                     </div>
                  )
               }
               )}
            </div></> : <div className="alert-danger font-semibold mt-[155px]">Sin resultados en la búsqueda! o no existen plataformas registradas</div> : <div className="mt-[155px]"><SpinnerData>Cargando plataformas, por favor espere...</SpinnerData></div>}
      </Wrapper>
   )
}
export default SocialPlataforms;