import { useState } from "react";
import { FaCoins, FaDesktop, FaGifts } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa6";
import { IoBag, IoBagCheck, IoCash, IoChevronDown, IoChevronUp, IoEllipse, IoFingerPrint, IoGitNetwork, IoHome, IoPerson } from "react-icons/io5";
import { MdDashboard, MdHistory, MdManageAccounts } from "react-icons/md";
import { SiGnusocial } from "react-icons/si";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Sidebar = ({ side, closeSidebar }) => {
  const { t } = useTranslation();
  const { configuration } = useSelector((state) => state.userReducer);
  const [OpenCloseManageProfile, SetOpenCloseManageProfile] = useState(false)
  const [OpenCloseManageOrders, SetOpenCloseManageOrders] = useState(false)
  const [OpenCloseManageRecharge, SetOpenCloseManageRecharge] = useState(false)

  const HandleOpenCloseManageProfile = (e) => {
    if (OpenCloseManageProfile === false) {
      SetOpenCloseManageProfile(true)
    } else {
      SetOpenCloseManageProfile(false)
    }
  }

  const HandleOpenCloseManageOrders = (e) => {
    if (OpenCloseManageOrders === false) {
      SetOpenCloseManageOrders(true)
    } else {
      SetOpenCloseManageOrders(false)
    }
  }

  const HandleOpenCloseManageRecharge = (e) => {
    if (OpenCloseManageRecharge === false) {
      SetOpenCloseManageRecharge(true)
    } else {
      SetOpenCloseManageRecharge(false)
    }
  }

  return (
    <div>
      {
        side === "" && <div className="bg-brand-50 bg-opacity-30 fixed inset-0 z-40" onClick={closeSidebar}></div>
      }

      <aside id="logo-sidebar"
        class={`fixed left-0 z-40 md:z-40 w-72 h-screen transition-all duration-500 bg-white dark:bg-brand-900 backdrop-blur-xl lg:translate-x-0 ${side} `} aria-label="Sidebar">
        <div class="relative h-full px-3 py-3 pb-4 overflow-y-auto">
          <div className="sticky z-30 bg-lightPrimary dark:bg-darkPrimary top-0 left-0 flex items-center w-full h-24">
            <div className="w-full flex items-center justify-center cursor-pointer w-[165px] h-24">
              {
                <Link to="/" className="">
                  <LazyLoadImage
                    className="w-[150px] h-[60px]"
                    alt={'No image logo'}
                    src={`/images/logos/${configuration?.logo}`}
                  />
                </Link>
              }
            </div>
          </div>
          <ul class="space-y-2 mt-4">
            {/*
              <li className="hs-accordion">
              <Link to={'/manage-account/dashboard'} class=" flex items-center w-full p-2 text-sm font-bold text-brand-700 dark:text-brand-300 rounded-lg 
              transition duration-700 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary  pt-4 pb-4">
                <MdDashboard className="flex-shrink-0 w-6 h-6 
                group-hover:text-brand-700 dark:text-brand-300"/>
                <span class="flex-1 ml-3 text-left whitespace-nowrap">Dashboard</span>
              </Link>
            </li>*/
            }

            <li className="hs-accordion">
              <button onClick={HandleOpenCloseManageProfile} type="button" class="flex items-center w-full p-2 text-sm font-bold text-brand-700 dark:text-brand-300 rounded-lg 
               transition duration-700 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary pt-4 pb-4" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <MdManageAccounts className="flex-shrink-0 w-6 h-6" />
                <span class="flex-1 ml-3 text-left whitespace-nowrap group-hover:font-semibold" sidebar-toggle-item>{t('manageprofile')}</span>
                {
                  OpenCloseManageProfile ?
                    <FaAngleRight size={16} className="absolute right-4 rotate-90 transition-transform ease-in-out duration-500" />
                    :
                    <FaAngleRight size={16} className="absolute right-4 transition-transform ease-in-out duration-500" />
                }
              </button>
              <ul id="dropdown-example" className={`${OpenCloseManageProfile ? `h-[52px]` : 'h-[0px]'} block transition-all ease-in-out duration-500 overflow-hidden`}>
                <li>
                  <Link to="/manage-account/profile" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-4 pt-4 pb-4 transition duration-700 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary">
                    <IoEllipse size={14} className="flex-shrink-0 mr-2" /><span className="group-hover:font-semibold">{t('myprofile')}</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <button onClick={HandleOpenCloseManageOrders} type="button" class="flex items-center w-full p-2 text-sm font-bold text-brand-700 dark:text-brand-300 rounded-lg 
              transition duration-700 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary  pt-4 pb-4" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <IoBag className="flex-shrink-0 w-6 h-6 transition duration-75" />
                <span class="flex-1 ml-3 text-left whitespace-nowrap group-hover:font-semibold" sidebar-toggle-item>{t('manageorders')}</span>
                {
                  OpenCloseManageOrders ?
                    <FaAngleRight size={16} className="absolute right-4 rotate-90 transition-transform ease-in-out duration-500" />
                    :
                    <FaAngleRight size={16} className="absolute right-4 transition-transform ease-in-out duration-500" />
                }
              </button>
              <ul id="dropdown-example" className={`${OpenCloseManageOrders ? `h-[153px]` : 'h-[0px]'} block transition-all ease-in-out duration-500 overflow-hidden`}>
                <li>
                  <Link to="/manage-account/userorders" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-4 pt-4 pb-4 transition duration-700 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary">
                    <IoEllipse size={14} className="flex-shrink-0 mr-2" /><span className="group-hover:font-semibold">Streaming</span>
                  </Link>
                </li>
                <li>
                  <Link to="/manage-account/mygiftcards" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-4 pt-4 pb-4 transition duration-700 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary">
                    <IoEllipse size={14} className="flex-shrink-0 mr-2" /><span className="group-hover:font-semibold">{t('giftcards')}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/manage-account/usersocialorders" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-4 pt-4 pb-4 transition duration-700 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary">
                    <IoEllipse size={14} className="flex-shrink-0 mr-2" /><span className="group-hover:font-semibold">{t('socials')}</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <button onClick={HandleOpenCloseManageRecharge} type="button" class="flex items-center w-full p-2 text-sm font-bold text-brand-700 dark:text-brand-300 rounded-lg 
              transition duration-700 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary  pt-4 pb-4" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <IoCash className="flex-shrink-0 w-6 h-6 transition duration-75" />
                <span class="flex-1 ml-3 text-left whitespace-nowrap group-hover:font-semibold" sidebar-toggle-item>{t('managerecharges')}</span>
                {
                  OpenCloseManageRecharge ?
                    <FaAngleRight size={16} className="absolute right-4 rotate-90 transition-transform ease-in-out duration-500" />
                    :
                    <FaAngleRight size={16} className="absolute right-4 transition-transform ease-in-out duration-500" />
                }
              </button>
              <ul id="dropdown-example" className={`${OpenCloseManageRecharge ? `h-[105px]` : 'h-[0px]'} block transition-all ease-in-out duration-500 overflow-hidden`}>
                <li>
                  <Link to="/manage-account/recharge" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-4 pt-4 pb-4 transition duration-700 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary">
                    <IoEllipse size={14} className="flex-shrink-0 mr-2" /><span className="group-hover:font-semibold">{t('recharge')}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/manage-account/paymentshistory" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-4 pt-4 pb-4 transition duration-700 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary">
                    <IoEllipse size={14} className="flex-shrink-0 mr-2" /><span className="group-hover:font-semibold">{t('history')}</span>
                  </Link>
                </li>
              </ul>
            </li>

          </ul>
        </div>
      </aside >
    </div >
  );
};
export default Sidebar;
