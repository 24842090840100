import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Spinner from "../Spinner";
import { MdOutlinePassword } from 'react-icons/md';
import { Button, FormControl, IconButton, MenuItem, Select, TextField } from "@mui/material";
import moment from 'moment';
import 'moment/locale/es';
import { useNavigate } from "react-router-dom";
import { uriSerialized } from "../../utils/uriSerialized";
import Nav from "./Nav";
import { FaEnvelopeCircleCheck } from "react-icons/fa6";
import { verificarCodigo } from "../../store/features/slices/cuentaSlice";
import { message, validarEmail } from "../../utils/Funciones";
import { IoReloadSharp } from "react-icons/io5";
import { FaKey } from "react-icons/fa";
import { SET_TO_DEFAULT_RESPONSE } from "../../store/features/slices/cuentaSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";

const VerifyCodeNew = ({ }) => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { isMutation, verifyCodeEmail } = useSelector((state) => state.cuentaReducer);
   const [state, setState] = useState({
      type: 'netflix',
      email: ''
   })

   const SubmitHandleVerifyCode = (e) => {
      e.preventDefault()

      if (!validarEmail(state.email)) {
         message('el correo tiene un formato inválido', 'error', 5)
      } else {
         const queryOptions = {
            email: state.email,
            type: state.type
         }
         const query = queryOptions ? "?" + uriSerialized(queryOptions) : ""
         dispatch(verificarCodigo(query))
      }
   }

   const extractTableFromHTML = (html) => {

      const matchHtml = state.type === 'netflix' ?
         html.match(/<table[^>]*class="inner-button border-false"[^>]*>[\s\S]*?<\/table>/)
         :
         html.match(/<table[^>]*class="module"[^>]*>[\s\S]*?<\/table>/g)[2].match(/<tr>([\s\S]*?)<\/tr>/g)[2];

      const tableMatch = state.type === 'netflix' ? matchHtml : [matchHtml];
      return tableMatch ? tableMatch[0] : '';
   };

   useEffect(() => {
      if (isMutation.success) {
         SET_TO_DEFAULT_RESPONSE()
         const linkNetflix = document.querySelector('a.h5');
         if (linkNetflix) {
            linkNetflix.click();
         }
      }
   }, [isMutation.success])

   return (
      <div className="w-full bg-brand-100 dark:bg-brand-950 min-h-screen">
         <Nav />
         <div className="w-full md:w-10/12 m-auto p-4 relative">
            {/* HEADER PAGES */}
            <div className="sticky p-4 flex flex-wrap text-brand-900 dark:text-brand-100 uppercase w-[calc(100%-30px)] font-semibold md:w-[calc(100%-80px)] lg:w-[calc(100%-320px)] bg-gradient-to-r from-white dark:from-brand-900 backdrop-blur-lg z-30 rounded-xl">
               <div className="w-full h-14 text-lg md:text-2xl flex items-center">
                  <div className="flex items-center w-full">
                     <MdOutlinePassword size={65} style={{ marginRight: 10 }} />
                     Verificar códigos
                  </div>
               </div>
            </div>

            <div className="w-full mt-5 md:w-12/12 flex justify-center">
               <form className="relative w-full md:w-4/12 flex flex-wrap" onSubmit={SubmitHandleVerifyCode}>
                  <div className="w-full md:w-12/12 p-3">
                     <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Seleccionar plataforma</label>
                     <FormControl fullWidth>
                        <Select
                           required
                           name="type"
                           variant="outlined"
                           value={state.type}
                           onChange={(e) => setState({ ...state, type: e.target.value })}
                        >
                           <MenuItem value={'netflix'} key={'netflix'} className="flex items-center">Netflix</MenuItem>
                           <MenuItem value={'disney'} key={'disney'} className="flex items-center">Disney</MenuItem>
                        </Select>
                     </FormControl>
                  </div>

                  <div className="w-full md:w-12/12 p-3">
                     <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Ingresar el correo {state.type && `de ${state.type}`}</label>
                     <input
                        required
                        name="email"
                        className="custom-style-input"
                        sid="outlined-basic" variant="outlined"
                        autoComplete="off"
                        type="email"
                        value={state.email}
                        onChange={(e) => setState({ ...state, email: e.target.value })}
                     />
                  </div>
                  <div className="w-full p-3 mt-1">
                     <Button
                        sx={{
                           "&.Mui-disabled": {
                              background: "#525252",
                              color: "#a3a3a3"
                           }
                        }}
                        disabled={isMutation.loading ? true : false}
                        type="submit" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                        size='large' variant="contained" color={`${state.type === 'netflix' ? 'error':'primary'}`} startIcon={isMutation.loading ? <Spinner w={'35px'} h={'35px'} /> : <FaEnvelopeCircleCheck size={24} />}>{isMutation.loading ? 'Verificando...' : 'Consultar código'}
                     </Button>
                  </div>
               </form>
            </div>
            {
               verifyCodeEmail &&
               <div className="w-full flex flex-wrap justify-center mt-3 bg-white dark:bg-brand-900 p-5">
                  <ul className="">
                     <div className="text-center font-semibold text-2xl mb-5 text-brand-950 dark:text-brand-50">{verifyCodeEmail.subject}</div>
                     <div className="flex items-center mb-5">
                        <LazyLoadImage
                           src={`${state.type === 'netflix' ? '/images/helpers/netflix.png' : '/images/helpers/disney.png'}`}
                           className="mr-2 rounded-full object-cover min-w-[65px] max-w-[65px] min-h-[65px] max-h-[65px]" />
                        <div className="ml-2 text-brand-950 dark:text-brand-50">
                           {state.email}
                        </div>
                     </div>
                     <div className="text-center text-4xl text-brand-950 dark:text-brand-50 font-bold" dangerouslySetInnerHTML={{ __html: verifyCodeEmail.body }} />
                     <div className="text-center mt-5 text-brand-950 dark:text-brand-50">código recibido {moment(verifyCodeEmail.date).fromNow()}</div>
                  </ul>
               </div>
            }
         </div>
      </div >
   )
}
export default VerifyCodeNew;