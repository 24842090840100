import { useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Spinner from "../../components/Spinner";
import { MdEditOff, MdVerifiedUser } from "react-icons/md";
import { Button, FormControl, MenuItem, Select, Tooltip } from "@mui/material";
import { currencyFormat, message, setUnixToTimeFormat } from "../../utils/Funciones.js";
import { MuiTelInput } from 'mui-tel-input'
import { IoSave } from "react-icons/io5";
import moment from 'moment';
import { FaChessKing, FaDollarSign, FaUserTie } from "react-icons/fa";
import { SET_TO_DEFAULT_RESPONSE, update } from "../../store/features/slices/userSlice";
const UpdateUser = ({ HandleCloseEditUser, user, socket }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [credits, SetCredits] = useState(0)
    const [state, setState] = useState({
        _id: "",
        name: "",
        email: "",
        password: "",
        admin: false,
        reseller: false,
        countrycode: "",
        phonenumber: "",
        credits_purchased: "",
        credits_used: "",
        created_at: "",
        locked: false,
        last_update: ""
    });

    useEffect(() => {
        setState({
            _id: user._id,
            name: user.name,
            email: user.email,
            password: "",
            admin: user.admin,
            reseller: user.reseller,
            countrycode: user.countrycode,
            phonenumber: user.phonenumber,
            credits_purchased: user.credits_purchased,
            credits_used: user.credits_used,
            created_at: moment(user.created_at).format("yyyy-MM-DDTHH:mm"),
            locked: user.locked,
            last_update: moment(user.last_update).format("yyyy-MM-DDTHH:mm"),
        })
        SetCredits(Number(user.credits_purchased - user.credits_used))
    }, [user])

    const {
        isMutation
    } = useSelector((state) => state.userReducer);

    const updateSubmit = e => {
        e.preventDefault();
        const CurrentInfo = {
            name: user.name,
            email: user.email,
            admin: user.admin,
            reseller: user.reseller,
            countrycode: user.countrycode,
            phonenumber: user.phonenumber,
        }

        const userEdited = {
            name: state.name,
            email: state.email,
            admin: state.admin,
            reseller: state.reseller,
            countrycode: state.countrycode,
            phonenumber: state.phonenumber,
        }


        if (JSON.stringify(CurrentInfo) !== JSON.stringify(userEdited) || state.password !== "") {
            if (state.password.trim().length > 0 && state.password.trim().length < 6) {
                message('la contraseña debe tener mínimo 6 caracteres', 'error', 4)
            } else {
                dispatch(update(state));
            }
        } else {
            message('no hubo ningún cambio', 'error', 3)
            HandleCloseEditUser()
        }
    }

    const HandleChangeEditUser = (e) => {

        setState({ ...state, [e.target.name]: e.target.value });
    }

    const onChangePhone = (newValue, info) => {
        setState({ ...state, ['countrycode']: info.countryCode, ['phonenumber']: newValue });
    }


    return (
        <form className="w-full" onSubmit={updateSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mb-5">
                <div className="w-full">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Nombre del usuario</label>
                    <input autoFocus name="name" className="custom-style-input"
                        autoComplete="off"
                        required
                        value={state.name}
                        type="text"
                        onChange={HandleChangeEditUser}
                    />
                </div>
                <div className="w-full">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Correo</label>
                    <input name="email" className="custom-style-input"
                        autoComplete="off"
                        required
                        value={state.email}
                        type="email"
                        onChange={HandleChangeEditUser}
                    />
                </div>


                <div className="w-full">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Contraseña <span className="text-xs text-amber-600 dark:text-amber-400">(dejar en blanco sino vas a modificar)</span></label>
                    <input name="password" className="custom-style-input"
                        autoComplete="off"
                        value={state.password}
                        onChange={HandleChangeEditUser}
                        type="password"
                    />
                </div>

                <div className="w-full">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Es admin?</label>
                    <div className="relative">
                        <FormControl fullWidth>
                            <Select
                                required
                                name="admin"
                                className="flex items-center"
                                variant="outlined"
                                value={state.admin}
                                onChange={HandleChangeEditUser}
                            >
                                <MenuItem value={true} key={true} selected={state.admin && true} className="flex items-center"><FaChessKing size={24} className="mr-2 text-red-500" />Administrador</MenuItem>
                                <MenuItem value={false} key={false} selected={!state.admin && false} className="flex items-center"><MdVerifiedUser size={24} className="mr-2 text-green-600 dark:text-green-400" />Registrado</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="w-full">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Es reseller?</label>
                    <div className="relative">
                        <FormControl fullWidth>
                            <Select
                                required
                                name="reseller"
                                className="flex items-center"
                                variant="outlined"
                                value={state.reseller}
                                onChange={HandleChangeEditUser}
                            >
                                <MenuItem value={true} key={true} selected={state.reseller && true} className="flex items-center"><FaDollarSign size={24} className="mr-2 text-yellow-500" />Reseller</MenuItem>
                                <MenuItem value={false} key={false} selected={!state.reseller && false} className="flex items-center"><FaUserTie size={24} className="mr-2 text-blue-500" />Consumidor</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="w-full">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Numero celular</label>
                    <MuiTelInput
                        inputProps={{ className: "text-brand-900 dark:text-brand-200" }}
                        value={state.phonenumber}
                        onChange={onChangePhone}
                        className="custom-style-input"
                    />
                </div>

                <div className="w-full">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Créditos disponibles</label>
                    <Tooltip title={
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <MdEditOff size={18} style={{ marginRight: 5 }} />
                            Creditos no se puede editar, puedes agregar créditos directo en la lista de usuarios.
                        </div>}

                        PopperProps={{
                            sx: {
                                "& .MuiTooltip-tooltip": {
                                    fontSize: 12.5,
                                }
                            }
                        }}
                        arrow>
                        <input autoFocus name="credits" className="custom-style-input"
                            placeholder="Créditos disponibles"
                            autoComplete="off"
                            value={currencyFormat(credits)}
                            type="text"
                            disabled
                        />
                    </Tooltip>
                </div>

                <div className="w-full">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Fecha de registro</label>
                    <Tooltip title={
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <MdEditOff size={18} style={{ marginRight: 5 }} />
                            Fecha de registro no se puede editar.
                        </div>}

                        PopperProps={{
                            sx: {
                                "& .MuiTooltip-tooltip": {
                                    fontSize: 12.5,
                                }
                            }
                        }}
                        arrow>
                        <input autoFocus name="credits" className="custom-style-input"
                            autoComplete="off"
                            value={setUnixToTimeFormat("created_at", state.created_at)}
                            type="text"
                            disabled
                        />
                    </Tooltip>
                </div>

                <div className="w-full">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Última vez que se actualizó</label>
                    <Tooltip title={
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <MdEditOff size={18} style={{ marginRight: 5 }} />
                            Última vez que se actualizó no se puede editar.
                        </div>}

                        PopperProps={{
                            sx: {
                                "& .MuiTooltip-tooltip": {
                                    fontSize: 12.5,
                                }
                            }
                        }}
                        arrow>
                        <input autoFocus name="credits" className="custom-style-input"
                            autoComplete="off"
                            value={setUnixToTimeFormat("last_update", state.last_update)}
                            type="text"
                            disabled
                        />
                    </Tooltip>
                </div>

            </div>
            <div className="flex m-auto w-full">
                <Button
                    type="submit"
                    style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                    size='large' variant="contained"
                    disabled={isMutation.uloading ? true : false}
                >
                    {isMutation.uloading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                    {isMutation.uloading ? 'Actualizando...' : 'Actualizar usuario'}
                </Button>
            </div>
        </form>
    )
}
export default UpdateUser;