import { useDispatch, useSelector } from "react-redux";
import { IoAlarm, IoAlert, IoAlertSharp, IoBagCheck, IoBan, IoBuild, IoCheckbox, IoCheckmark, IoCloseCircle, IoCloseCircleOutline, IoCloseSharp, IoDesktopSharp, IoEye, IoFingerPrint, IoLockClosed, IoLockOpen, IoLogoWhatsapp, IoPerson, IoPin, IoReload, IoReloadCircleSharp, IoRocket, IoSearch, IoSendSharp, IoSettings, IoSettingsSharp, IoThumbsDownSharp, IoTime, IoTimeSharp } from "react-icons/io5";
import Spinner from "../../components/Spinner";
import { CalcularExpiracion, CalcularTiempoTranscurrido, currencyFormat, message, setUnixToTimeFormat, sleep } from "../../utils/Funciones.js";
import { MdAllInbox, MdAlternateEmail, MdAvTimer, MdDelete, MdDevices, MdError, MdEventNote, MdLocalShipping, MdNearbyError, MdOutgoingMail, MdPayment, MdPhoneIphone, MdRunningWithErrors, MdSettings, MdTimer, MdTimerOff, MdVerified } from "react-icons/md";
import { Button, IconButton, TextField, Tooltip, Link, Pagination, Stack, PaginationItem } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import Wrapper from "./Wrapper";
import debounce from 'lodash.debounce';
import moment from 'moment';
import { FaClipboard, FaTrashAlt } from "react-icons/fa";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SpinnerData from "../../components/SpinnerData";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import Spinner2 from "../../components/Spinner2";
import { getBypage, SET_TO_DEFAULT_RESPONSE, contador as getContador, anularPayment } from "../../store/features/slices/paymentSlice";
import { uriSerialized } from "../../utils/uriSerialized";
import { FaBan, FaChessKing, FaRegCalendarPlus, FaRegUser, FaRocket, FaSquareCheck } from "react-icons/fa6";
import { Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import Socket, { authSocket, logoutSocket } from '../../utils/Socket';

const Payments = ({ }) => {
  const dispatch = useDispatch();
  const [verFilterPayments, SetFilterPayments] = useState('')
  const [curTime, SetCurTime] = useState(null)
  const [copied, setCopie] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [limit, setLimit] = useState(30);

  const {
    paymentsByPage,
    contador,
    isMutation,
    totalFilter,
    total
  } = useSelector((state) => state.paymentReducer);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    if (isMutation.success) {
      message(isMutation.message, 'success', 5)
      dispatch(getContador())
      dispatch(SET_TO_DEFAULT_RESPONSE())
    }

    const queryOptions = {
      page: currentPage,
      limit: limit,
      search: searchTerm.trim(),
    }
    const query = queryOptions ? "?" + uriSerialized(queryOptions) : ""
    dispatch(getBypage(query))
  }, [dispatch, searchTerm.trim(), currentPage, limit, isMutation.success]);

  useEffect(() => {
    dispatch(getContador())
  }, []);

  useEffect(() => {
    if (isMutation.updated) {
      message(isMutation.message, 'success', 5)
      if (isMutation.extra.user.locked) {
        Socket.emit('uUserInfo', { newData: isMutation.extra.user, action: 'lockUser' })
      }
      dispatch(SET_TO_DEFAULT_RESPONSE())
    }
  }, [isMutation.updated]);

  useEffect(() => {
    setInterval(async function () {
      await sleep(300)
      SetCurTime(new Date().toLocaleString());
    }.bind(this), 1000);
  }, [])

  const alertaConfirmar = (titulo, texto, icon, callback) => {
    Swal.fire({
      title: titulo,
      html: texto,
      icon: icon,
      showCancelButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#2C3E50',
      cancelButtonColor: '#CB4335'
    }).then((result) => {
      if (result.isConfirmed) {
        callback(result.isConfirmed)
      }
    })
  }

  const handleAnularPayment = (_id) => {
    alertaConfirmar(`ANULAR RECARGA`, `Estas seguro que quieres anular esta recarga?`, 'question', (res_alert) => {
      if (res_alert === true) {
        dispatch(anularPayment({ _id }))
      }
    })
  }

  const HandleChangeFilterPayments = async (state) => {
    setSearchTerm(state)
    SetFilterPayments(state)
    setCurrentPage(1);
  }

  const changeHandleSearch = (event) => {
    SetFilterPayments('')
    setSearchTerm(event.target.value)
    setCurrentPage(1);
  };

  const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 500), []);

  const handleChangePage = (e, p) => {
    setCurrentPage(p);
  };

  const onChangeSearchTermClear = e => {
    setSearchTerm("");
    HandleChangeFilterPayments("")
    document.getElementById("search").value = "";
  };

  const onCopy = (text) => {
    setCopie(true);
    message(text, 'success', 2)
  }

  return (
    <Wrapper>

      <div className="fixed pl-3 md:pl-3 lg:pl-3 h-8 top-11 z-30 flex items-center w-8/12 text-brand-900 dark:text-brand-100 text-[1.2rem] md:text-[1.8rem] lg:text-[2rem] font-semibold">
        Recargas
        <div class="flex items-center justify-center px-3 ml-3 text-xl font-medium bg-brand-300 dark:bg-brand-800 text-brand-800 dark:text-brand-200 rounded-full">
          {!contador.loading && contador.total}
        </div>
      </div>
      {/* HEADER PAGES */}
      <div className="fixed mt-8 p-4 flex items-center text-brand-900 dark:text-brand-100 uppercase w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] font-semibold bg-white dark:bg-brand-900 backdrop-blur-lg z-20 rounded-b-xl">
        <div className="!w-9/12">
          <InputGroup className="w-full md:w-[450px] md:max-w-[450px]">
            <InputLeftElement pointerEvents="none" className="!mt-[14px] !ml-2">
              <FiSearch size={24} className="text-brand-900 dark:text-brand-100" />
            </InputLeftElement>
            <Input size='lg' autoFocus id="search" variant='flushed' onChange={debouncedChangeHandler} type="text" placeholder={`Buscar pedidos tarjetas de regalo...`} className="flex w-full md:w-[450px] md:max-w-[450px] !h-14 !pl-10 !items-center !justify-center !border-2 rounded-xl !bg-brand-200 
            dark:!bg-brand-950 !text-sm focus:!ring-lightPrimary dark:focus:!ring-darkPrimary focus:!border-lightPrimary dark:focus:!border-darkPrimary 
            !outline-none !border-brand-300 dark:!border-brand-800 !text-brand-900 dark:!text-white !relative !placeholder-brand-500" />
            <InputRightElement className="!mt-[8px] !mr-1">
              <IconButton onClick={onChangeSearchTermClear} className={`mr-2 ${searchTerm.trim().length > 0 ? 'scale-100' : 'scale-0'} transition-all duration-500 text-brand-600 dark:text-brand-300`}>
                <IoCloseSharp size={20} className="text-brand-900 dark:text-brand-100" />
              </IconButton>
            </InputRightElement>
          </InputGroup>
        </div>
      </div>

      <div className="w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] mb-0 mt-[102px] pb-3 pt-5 md:pt-5 lg:pt-10 fixed z-20 bg-white/10 dark:bg-[#00000023] backdrop-blur-lg flex flex-col md:flex-col lg:flex-row items-center">
        <div className="order-1 md:order-1 lg:order-2 w-full md:w-full lg:w-6/12 flex items-center justify-center md:justify-center lg:justify-end text-brand-200 mb-6 md:mb-6 lg:mb-0">
          {!isMutation.loading ?
            <>
              <div className="hidden md:block">
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                    page={currentPage}
                    onChange={handleChangePage}
                    variant="text"
                    color="primary"
                    shape="rounded"
                    size="large"
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...item}
                      />
                    )}
                    className="bg-white dark:bg-brand-900"
                  />
                </Stack>
              </div>
              <div className="block md:hidden">
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                    page={currentPage}
                    onChange={handleChangePage}
                    variant="text"
                    color="primary"
                    shape="rounded"
                    size="small"
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...item}
                      />
                    )}
                    className="bg-white dark:bg-brand-900"
                  />
                </Stack>
              </div>
            </> : <Spinner />
          }
        </div>
        <div className="order-2 md:order-2 lg:order-1 w-full md:w-full lg:w-6/12">
          <Tooltip title="Click aquí para filtrar recargas pendientes" arrow={true}>
            <div onClick={() => HandleChangeFilterPayments("pending")} className={`flex inline-flex cursor-pointer items-center mb-8 ${verFilterPayments === "pending" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-yellow-400 p-2 md:p-3 rounded-lg`}>
              <div className="flex inline-flex items-center"><IoBuild className="mr-0 text-2xl md:text-4xl" /><span className="hidden md:block"></span> </div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-500"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                    {!contador.loading ? contador.pending : <Spinner2 />}

                  </span>
                </span>
              </div>
            </div>
          </Tooltip>

          <Tooltip title="Click aquí para filtrar recargas rechazadas" arrow={true}>
            <div onClick={() => HandleChangeFilterPayments("rejected")} className={`flex inline-flex cursor-pointer items-center mb-8 ${verFilterPayments === "rejected" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-red-500 p-2 md:p-3 rounded-lg ml-3 md:ml-5`}>
              <div className="flex inline-flex items-center"><IoBuild className="mr-0 text-2xl md:text-4xl" /><span className="hidden md:block"></span> </div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                    {!contador.loading ? contador.rejected : <Spinner2 />}

                  </span>
                </span>
              </div>
            </div>
          </Tooltip>

          <Tooltip title="Click aquí para filtrar recargas canceladas" arrow={true}>
            <div onClick={() => HandleChangeFilterPayments("canceled")} className={`flex inline-flex cursor-pointer items-center mb-8 ${verFilterPayments === "canceled" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-red-500 p-2 md:p-3 rounded-lg ml-3 md:ml-5`}>
              <div className="flex inline-flex items-center"><IoCloseCircle className="mr-0 text-2xl md:text-4xl" /><span className="hidden md:block"></span> </div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                    {!contador.loading ? contador.canceled : <Spinner2 />}

                  </span>
                </span>
              </div>
            </div>
          </Tooltip>

          <Tooltip title="Click aquí para filtrar todas las recargas" arrow={true}>
            <div onClick={() => HandleChangeFilterPayments("")} className={`flex inline-flex cursor-pointer items-center mb-8 ${verFilterPayments === "" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-green-400 p-2 md:p-3 rounded-lg ml-3 md:ml-5`}>
              <div className="flex inline-flex items-center"><MdAllInbox className="mr-0 text-2xl md:text-4xl" /><span className="hidden md:block"></span> </div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                    {!contador.loading ? contador.total : <Spinner2 />}
                  </span>
                </span>
              </div>
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="relative w-full">
        {!isMutation.loading ? paymentsByPage.length > 0 ?
          <div className="flex flex-wrap mt-[310px] md:mt-[335px] lg:mt-[290px]">
            {
              paymentsByPage.map((payment) => {

                return (
                  <div className={`p-4 relative w-full shadow-xl shadow-neutral-200 dark:shadow-none cursor-pointer ${payment.status === 'rejected' && 'bg-gradient-to-r from-[#ea06068c]'} bg-white dark:bg-brand-900 text-brand-900 dark:text-brand-200 flex flex-wrap mb-12 rounded-lg animation`} key={payment._id}>
                    <label className="absolute -top-8 left-0 p-2 flex items-center text-brand-800 dark:text-brand-200 text-base rounded-tl-lg rounded-tr-lg bg-white dark:bg-brand-900">
                      <span className="text-[12px]">{payment.payment_id}</span>
                    </label>
                    <div className="grid grid-wrap w-full md:w-full lg:w-3/12">
                      <div className="flex inline-flex items-center">
                        <LazyLoadImage
                          className="w-14 h-14 z-10 rounded-full flex inline-flex object-cover"
                          alt={`No image ${payment.method}`}
                          effect="opacity"
                          src={`/images/paymethods/${payment.method}.webp`}
                        />
                        <div className="grid gap-1 self-center">
                          <div className="ml-2 text-sm font-semibold uppercase">{payment.method}</div>
                          <div className="ml-2 flex items-center text-xs truncate ..."><MdAlternateEmail size={16} className='mr-[5px]' />  {payment.user?.email}
                            <span className="ml-3">
                              <Tooltip title="copiar" arrow={true}>
                                <div>
                                  <CopyToClipboard text={payment.user.email} onCopy={() => onCopy('correo copiado')}>
                                    <FaClipboard size={20} className="text-brand-900 dark:text-brand-200 cursor-pointer w-[35px]" />
                                  </CopyToClipboard>
                                </div>
                              </Tooltip>
                            </span>
                          </div>
                          <div className="ml-2 flex items-center text-xs truncate ..."><FaRegUser size={16} className='mr-[5px]' /> {payment.user?.name}</div>
                          <div className="ml-2 flex items-center text-xs mt-1 truncate ..."><FaRegCalendarPlus size={16} className='mr-[5px]' />{setUnixToTimeFormat('created_at', payment.created_at)}</div>
                        </div>
                      </div>
                    </div>
                    <div className="relative md:flex grid grid-cols-1 md:grid-cols-1 gap-3 items-center w-full md:w-full lg:w-[calc(75%-50px)] mt-5 md:mt-4 lg:mt-0">

                      <div className="md:w-3/12 w-full grid">
                        <div className="flex items-center justify-center uppercase text-sm">Origen</div>
                        <div className="flex items-center justify-center">
                          <div className="flex inline-flex items-center">
                            {payment.payment_type === 'admin' ?
                              <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><FaChessKing size={24} className="mr-2" />ADMINISTRADOR</div>
                              :
                              <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2"><MdPayment size={24} className="mr-2" />PASARELA</div>
                            }
                          </div>
                        </div>
                      </div>

                      <div className="md:w-3/12 w-full grid">
                        <div className="flex items-center justify-center uppercase text-sm">Monto</div>
                        <div className="flex items-center justify-center">
                          <div className="flex inline-flex items-center">
                            {payment.status === "complete" && <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2">{currencyFormat(payment.total_recharge)}</div>}
                            {payment.status === "pending" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2">{currencyFormat(payment.total_recharge)}</div>}
                            {payment.status === "rejected" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2">{currencyFormat(payment.total_recharge)}</div>}
                            {payment.status === "canceled" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2">{currencyFormat(payment.total_recharge)}</div>}
                          </div>
                        </div>
                      </div>

                      <div className="md:w-3/12 w-full grid">
                        <div className="flex items-center justify-center uppercase text-sm">Estado</div>
                        <div className="flex items-center justify-center">
                          <div className="flex inline-flex items-center">
                            {payment.status === "complete" && <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2"><FaSquareCheck size={24} className="mr-2" />Completado</div>}
                            {payment.status === "pending" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><FaRocket size={24} className="mr-2" />Pendiente</div>}
                            {payment.status === "rejected" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><FaBan size={24} className="mr-2" />Rechazado</div>}
                            {payment.status === "canceled" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><IoCloseCircle size={24} className="mr-2" />Cancelado</div>}
                          </div>
                        </div>
                      </div>

                      <div className="md:w-3/12 w-full grid">
                        <div className="flex items-center justify-center uppercase text-sm">Observaciones</div>
                        <div className="flex items-center justify-center">
                          <div className="flex inline-flex items-center">
                            <div class="text-center text-blue-950 dark:text-blue-50 bg-gradient-to-r from-blue-400 dark:from-blue-500 rounded-l-lg flex items-center p-2 text-xs"><MdEventNote size={24} className="mr-2" />
                              {payment.observations}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="flex items-center gap-2 justify-end w-full md:w-full lg:md:w-[50px] mt-5 md:mt-5 lg:mt-0">
                      <Tooltip title="eliminar" arrow={true}>
                        <Button disabled={payment.status === 'rejected' ? false : true} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                          <FaTrashAlt className="w-5 h-5 text-white" />
                        </Button>
                      </Tooltip>
                      <Tooltip title="cancelar recarga" arrow={true}>
                        <Button onClick={() => handleAnularPayment(payment._id)} disabled={payment.status === 'complete' ? false : true} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                          <IoCloseCircle className="w-5 h-5 text-white" />
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                )
              })
            }
          </div> : <div className="alert-danger font-semibold mt-[310px] md:mt-[335px] lg:mt-[290px]">Sin resultados en la búsqueda! o no existen pedidos registradas</div> : <div className="mt-[310px] md:mt-[335px] lg:mt-[290px]"><SpinnerData>Cargando recargas, por favor espere...</SpinnerData></div>}
      </div>
    </Wrapper>
  );
};

export default Payments;
