import { useState, useEffect } from "react"
import { MdEditOff } from "react-icons/md";
import { Button, FormControl, MenuItem, Select, Tooltip } from "@mui/material";
import { message } from "../../utils/Funciones.js";
import { IoSave } from "react-icons/io5";
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import 'moment/locale/es';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Spinner from "../../components/Spinner.js";
import { useDispatch, useSelector } from "react-redux";
import { updateService } from "../../store/features/slices/socialSlice";
import Switch from "../../components/switch/index.jsx";
import { CURRENCY } from '../../utils/Constants';

const UpdateSubCategorySocial = ({ serviceData }) => {
    const dispatch = useDispatch();
    const [tipoentrega, SetTipoEntrega] = useState(false)
    const [enabled, SetEnabled] = useState(true)
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    const [state, setState] = useState({
        _id: "",
        subcategoryId: "",
        serviceId: "",
        servicetype: "",
        name: "",
        discountvip: 0,
        preciofijouser: 0,
        preciofijoreseller: 0,
        porcentajeadicional: 0,
        tipoentrega: false,
        enabled: true,
        speedseconds: 0,
        policy: "",
        urlexamplephone: "",
        urlexampledesktop: ""

    });

    const {
        isMutation
    } = useSelector((state) => state.socialReducer);

    const updateSubmit = e => {
        e.preventDefault();
        const contentState = editorState.getCurrentContent();
        if (contentState.hasText()) {
            const formData = new FormData();
            formData.append('data', JSON.stringify(state));
            formData.append('rawData', JSON.stringify(convertToRaw(contentState)));
            dispatch(updateService(formData))
        } else {
            message('Términos y condiciones de servicio', 'error', 4)
        }
    }

    useEffect(() => {
        const subcategory = {
            _id: serviceData._id,
            subcategoryId: serviceData._id,
            serviceId: serviceData.serviceId,
            name: serviceData.name,
            preciofijouser: serviceData.preciofijouser ? serviceData.preciofijouser : 0,
            preciofijoreseller: serviceData.preciofijoreseller ? serviceData.preciofijoreseller : 0,
            porcentajeadicional: serviceData.porcentajeadicional ? serviceData.porcentajeadicional : 0,
            tipoentrega: serviceData.tipoentrega,
            discountvip: serviceData.discountvip,
            enabled: serviceData.enabled,
            servicetype: serviceData.servicetype ? serviceData.servicetype : "",
            urlexamplephone: serviceData.urlexamplephone ? serviceData.urlexamplephone : "",
            urlexampledesktop: serviceData.urlexampledesktop ? serviceData.urlexampledesktop : "",
            speedseconds: serviceData.speedseconds ? serviceData.speedseconds : 0,
            policy: serviceData.policy ? serviceData.policy : "",
        }
        setState({ ...subcategory })
        SetTipoEntrega(serviceData.tipoentrega)
        SetEnabled(serviceData.enabled)

        const rawContent = serviceData.policy ? serviceData.policy : JSON.stringify({ "blocks": [{ "key": "dq4ce", "text": "okk", "type": "unstyled", "depth": 0, "inlineStyleRanges": [], "entityRanges": [], "data": {} }], "entityMap": {} });
        const contentState = convertFromRaw(JSON.parse(rawContent));
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState)

    }, [serviceData]);

    const HandleChangeEditSubCategory = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }

    const OnChangeTipoEntrega = () => {
        SetTipoEntrega(!tipoentrega)
        setState({ ...state, ['tipoentrega']: !tipoentrega })
    }

    const OnChangeEnabled = () => {
        SetEnabled(!enabled)
        setState({ ...state, ['enabled']: !enabled })
    }

    return (

        <form className="w-full" onSubmit={updateSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mb-5">
                <div className="w-full md:col-span-2">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Nombre de la categoría</label>
                    <input autoFocus name="name" className="custom-style-input"
                        autoComplete="off"
                        required
                        value={state.name}
                        type="text"
                        onChange={HandleChangeEditSubCategory}
                    />
                </div>
                <div className="w-full">
                    <label for="first_serviceId" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Id de servicio</label>
                    <Tooltip title={
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <MdEditOff size={18} style={{ marginRight: 5 }} />
                            Id de servicio no se puede editar
                        </div>}

                        PopperProps={{
                            sx: {
                                "& .MuiTooltip-tooltip": {
                                    fontSize: 12.5,
                                }
                            }
                        }}
                        arrow>
                        <input name="serviceId" className="custom-style-input"
                            placeholder="Id de servicio"
                            autoComplete="off"
                            required
                            disabled
                            value={state.serviceId}
                            type="text"
                        /></Tooltip>
                </div>
                <div className="w-full relative">
                    <label for="first_preciofijouser" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Precio fijo usuario</label>
                    <input name="preciofijouser" className="custom-style-input pl-8"
                        autoComplete="off"
                        required
                        min={0}
                        step={0.01}
                        onWheel={(e) => e.target.blur()}
                        value={state.preciofijouser}
                        type="number"
                        onChange={HandleChangeEditSubCategory}
                    />
                    <div class="absolute text-brand-900 dark:text-brand-200 inset-y-4 mt-7 left-0 pl-2 flex items-center text-lg leading-5">
                        {CURRENCY}
                    </div>
                </div>
                <div className="w-ful relative">
                    <label for="first_preciofijoreseller" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Precio fijo reseller</label>
                    <input name="preciofijoreseller" className="custom-style-input pl-8"
                        autoComplete="off"
                        required
                        min={0}
                        step={0.01}
                        onWheel={(e) => e.target.blur()}
                        value={state.preciofijoreseller}
                        type="number"
                        onChange={HandleChangeEditSubCategory}
                    />
                    <div class="absolute text-brand-900 dark:text-brand-200 inset-y-4 mt-7 left-0 pl-2 flex items-center text-lg leading-5">
                        {CURRENCY}
                    </div>
                </div>
                <div className="w-ful relative">
                    <label for="first_porcentajeadicional" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Porcentaje adicional aplicado</label>
                    <input name="porcentajeadicional" className="custom-style-input pl-8"
                        autoComplete="off"
                        required
                        min={0}
                        max={100}
                        value={state.porcentajeadicional}
                        type="number"
                        onChange={HandleChangeEditSubCategory}
                    />
                    <div class="absolute text-brand-900 dark:text-brand-200 inset-y-4 mt-7 left-0 pl-2 flex items-center text-lg leading-5">
                        %
                    </div>
                </div>
                <div className="w-full relative">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Descuento VIP</label>
                    <input name="discountvip" className="custom-style-input pl-8"
                        autoComplete="off"
                        required
                        value={state.discountvip}
                        type="number"
                        step={0.01}
                        onChange={HandleChangeEditSubCategory}
                        onWheel={(e) => e.target.blur()}
                        min={0}
                    />
                    <div class="absolute text-brand-900 dark:text-brand-200 inset-y-4 mt-7 left-0 pl-2 flex items-center text-lg leading-5">
                        {CURRENCY}
                    </div>
                </div>
                <div className="w-ful">
                    <label for="first_speedseconds" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Tiempo estimado para completar (segundos)</label>
                    <input name="speedseconds" className="custom-style-input"
                        autoComplete="off"
                        required
                        min={0}
                        value={state.speedseconds}
                        type="number"
                        onChange={HandleChangeEditSubCategory}
                    />
                </div>

                <div className="w-ful">
                    <label for="first_urlexamplephone" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Ejemplo url para celular</label>
                    <input name="urlexamplephone" className="custom-style-input"
                        autoComplete="off"
                        required
                        value={state.urlexamplephone}
                        type="text"
                        onChange={HandleChangeEditSubCategory}
                    />
                </div>

                <div className="w-full">
                    <label for="first_urlexampledesktop" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Ejemplo url para computadora</label>
                    <input name="urlexampledesktop" className="custom-style-input"
                        placeholder="Ejemplo url para computadora"
                        autoComplete="off"
                        required
                        value={state.urlexampledesktop}
                        type="text"
                        onChange={HandleChangeEditSubCategory}
                    />
                </div>

                <div className="w-full">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Tipo de servicio</label>
                    <FormControl fullWidth>
                        <Select
                            required
                            name="servicetype"
                            className="flex items-center"
                            variant="outlined"
                            value={state.servicetype}
                            onChange={HandleChangeEditSubCategory}
                        >
                            <MenuItem value={'default'} key={'default'} className="flex items-center">Default</MenuItem>
                            <MenuItem value={'customcomments'} key={'customcomments'} className="flex items-center">Custom comments</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className="w-full">
                    <label className="text-brand-900 dark:text-brand-200 text-sm">Tipo de entrega <span className="font-semibold">{tipoentrega ? "automática" : "manual"}</span></label>
                    <div className="h-10 mt-3">
                        <Switch
                            value={tipoentrega}
                            onChange={OnChangeTipoEntrega}
                        />
                    </div>
                </div>

                <div className="w-full md:col-span-2">
                    <label className="text-brand-900 dark:text-brand-200 text-sm">Estado del servicio <span className="font-semibold">{enabled ? "habilitado" : "deshabilitado"}</span></label>
                    <div className="h-10 mt-3">
                        <Switch
                            value={enabled}
                            onChange={OnChangeEnabled}
                        />
                    </div>
                </div>

                <div className="w-full md:col-span-2">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Términos y condiciones del servicio</label>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        placeholder="Escribe aquí..."
                    />
                </div>
            </div>
            <div className="flex m-auto w-full  md:w-12/12 w-full">
                <Button
                    type="submit"
                    disabled={isMutation.uloading ? true : false}
                    style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                    size='large' variant="contained"
                >
                    {isMutation.uloading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                    {isMutation.uloading ? 'Actualizando...' : 'Actualizar SubCategoria'}
                </Button>
            </div>
        </form>
    )
}
export default UpdateSubCategorySocial;