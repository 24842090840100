import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import AccountList from "../../components/home/AccountList";
import UserScreenHeader from "../../components/home/UserScreenHeader";
import { IoCloseSharp, IoGlobeSharp, IoLockClosed, IoMail, IoPerson, IoSave, IoTimer } from "react-icons/io5";
import { BsCashCoin, BsCashStack } from "react-icons/bs";
import { MdEdit, MdEmail, MdMarkEmailRead, MdPhone, MdPhoto, MdPhotoCamera, MdSave, MdVerifiedUser } from "react-icons/md";
import { FaCashRegister, FaUser } from "react-icons/fa";
import { CalcularExpiracion, currencyFormat, generateBackgroundColor, message, PermiteSoloLetras, setUnixToTimeFormat } from "../../utils/Funciones.js";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import PhotoPreview from "../../components/PhotoPreview";
import CardVip from "../../components/CardVip";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { LOGOUT, SET_TO_DEFAULT_RESPONSE, updateProfileInfo } from "../../store/features/slices/userSlice";
import { FaCoins, FaFingerprint, FaPhone, FaRegCalendarPlus } from "react-icons/fa6";
import { RiVipLine } from "react-icons/ri";
import moment from 'moment';
import 'moment/locale/es';
import { useTranslation } from 'react-i18next';

const Profile = ({ }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userInfo, isMutation, configuration, userToken } = useSelector((state) => state.userReducer);

  const [email, SetEmail] = useState({ open: false, value: '' })
  const [name, SetName] = useState({ open: false, value: '' })
  const [password, SetPassword] = useState({ open: false, value: '', current: '' })
  const [phone, SetPhone] = useState({ open: false, code: 'PE', value: '' })
  const [preview, setPreview] = useState({ photo: '' })
  const [photo, SetPhoto] = useState('')

  useEffect(() => {
    if (isMutation.updated) {
      message(isMutation.message, 'success', 5)
      email.open && SetEmail({ ...email, open: false })
      name.open && SetName({ ...name, open: false })
      phone.open && SetPhone({ ...phone, open: false })
      preview.photo && setPreview({ ...preview, photo: '' })
      password.open && SetPassword({ ...password, open: false, value: '', current: '' })
      dispatch(SET_TO_DEFAULT_RESPONSE())
    }
  }, [isMutation.updated])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const imageHandle = e => {
    if (e.target.files.length !== 0) {
      SetPhoto(e.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview({ ...preview, [e.target.name]: reader.result })
      }
      reader.readAsDataURL(e.target.files[0]);
    }
  }


  const handleOpenEditInfo = (type) => {
    if (type === 'name') {
      SetName({ ...name, ['open']: true, ['value']: userInfo?.name })
    } else if (type === 'email') {
      SetEmail({ ...email, ['open']: true, ['value']: userInfo?.email })
    } else if (type === 'phone') {
      SetPhone({ ...phone, ['open']: true, ['value']: userInfo?.phonenumber })
    } else if (type === 'password') {
      SetPassword({ ...password, ['open']: true })
    }
  }

  const handleUpdateProfileIfo = (type) => {
    if (type === 'name') {
      if (userInfo?.name !== name.value) {
        if (name.value.trim().length < 8) {
          message('Nombre completo no deber tener menos de 8 caracteres!', 'error', 4)
        } else {
          const formData = new FormData();
          formData.append('data', JSON.stringify({ _id: userInfo?._id, type: type, data: name }));
          dispatch(updateProfileInfo(formData))
        }
      } else {
        message('El nombre no tuvo ningún cambio!', 'error', 4)
        SetName({ ...name, ['open']: false, ['value']: '' })
      }
    } else if (type === 'email') {
      var validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
      if (userInfo?.email !== email.value) {
        if (email.value.trim().length < 5) {
          message('Email no deber tener menos de 5 caracteres!', 'error', 4)
        } else if (!validEmail.test(email.value)) {
          message('Email ingresado tiene un formato incorrecto!', 'error', 4)
        } else {
          const formData = new FormData();
          formData.append('data', JSON.stringify({ _id: userInfo?._id, type: type, data: email }));
          dispatch(updateProfileInfo(formData))
        }
      } else {
        message('El email no tuvo ningún cambio!', 'error', 4)
        SetEmail({ ...email, ['open']: false, ['value']: '' })
      }

    } else if (type === 'phone') {
      if (userInfo?.phonenumber !== phone.value) {
        if (phone.value.trim().length < 8) {
          message('Celular no deber tener menos de 8 números!', 'error', 4)
        } else {
          const formData = new FormData();
          formData.append('data', JSON.stringify({ _id: userInfo?._id, type: type, data: phone }));
          dispatch(updateProfileInfo(formData))
        }
      } else {
        message('Número celular no tuvo ningún cambio!', 'error', 4)
        SetPhone({ ...phone, ['open']: false, ['value']: '', ['code']: 'PE' })
      }
    } else if (type === 'photo') {
      const formData = new FormData();
      formData.append('data', JSON.stringify({ _id: userInfo?._id, type: type }));
      formData.append('image1', photo)
      dispatch(updateProfileInfo(formData))
    } else if (type === 'password') {
      if (password.value.trim().length >= 6 && password.current.trim().length >= 6) {
        const formData = new FormData();
        formData.append('data', JSON.stringify({ _id: userInfo?._id, type: type, data: password }));
        dispatch(updateProfileInfo(formData))
        if (userToken) {
          dispatch(LOGOUT('userToken'))
        }
      } else {
        message('la contraseña debe tener mínimo 6 caracteres', 'error', 4)
        SetPassword({ ...password, ['open']: false, ['value']: '', ['current']: '' })
      }
    }
  }

  return (

    <AccountList>

      {/* HEADER PAGES */}
      <div className="fixed mt-8 p-4 flex flex-wrap text-brand-900 dark:text-brand-100 uppercase w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] bg-gradient-to-r from-white dark:from-brand-900 backdrop-blur-lg z-30 rounded-b-xl">
        <div className="w-full h-14 text-lg md:text-2xl flex items-center">
          <div className="flex items-center w-full">
            <FaUser size={65} style={{ marginRight: 10 }} />
            {t('myprofile')}
          </div>
        </div>
      </div>

      <div className="flex flex-wrap mt-[106px]">
        <div className="w-full md:w-12/12 grid justify-center mt-5">
          <div className="relative rounded-full w-56 h-56 flex m-auto">
            <div class="max-w-[200px] min-w-[200px] max-h-[200px] min-h-[200px] rounded-full flex inline-flex items-center justify-center">
              <label htmlFor="photo" class="absolute z-20 cursor-pointer top-0 left-0 bg-lightPrimary dark:bg-darkPrimary contrast-[80%] rounded-full p-3">
                <MdPhotoCamera size={32} className="text-white" />
                <input id="photo" accept="image/png,image/jpeg,image/webp" type="file" name="photo" class="hidden" onChange={imageHandle} />
              </label>
              {
                preview?.photo ? <PhotoPreview url={preview.photo} /> :
                  userInfo?.photo === 'unknowphoto.webp' ?
                    <div style={{ background: `${generateBackgroundColor(userInfo?.name)}` }} className="uppercase relative mr-4 max-w-[200px] min-w-[200px] max-h-[200px] min-h-[200px] rounded-full flex items-center justify-center text-white text-[120px] font-normal border-4 border-[#00008b] z-10">
                      {userInfo?.name.substr(0, 1)}
                    </div>
                    :
                    <LazyLoadImage
                      className="max-w-[200px] min-w-[200px] max-h-[200px] min-h-[200px] rounded-full object-cover"
                      alt={'No image'}
                      effect="opacity"
                      src={`/images/photos/${userInfo?.photo}`}
                    />
              }
            </div>
            <div className="absolute right-0 bottom-0">
              <LazyLoadImage
                className="object-cover rounded-lg h-8 w-12"
                alt={`No image`}
                effect="opacity"
                src={`/images/flags/4x3/${userInfo?.countrycode}.svg`}
              />
            </div>
            {
              preview.photo &&
              <div className="absolute bottom-0 left-0 brounded-lg h-8 w-12g-blue-900  rounded-full">
                <IconButton onClick={() => handleUpdateProfileIfo('photo')} color="primary">
                  <MdSave size={32} className="text-brand-300" />
                </IconButton>
              </div>
            }

          </div>

          <div className="relative w-full md:min-w-[500px] flex items-center mt-5 shadow-lg shadow-brand-300 dark:shadow-black bg-brand-100 dark:bg-brand-900 text-brand-900 dark:text-brand-100">
            <div className="rounded-full bg-brand-100 dark:bg-brand-900 w-16 h-16 flex inline-flex items-center justify-center mr-0">
              <IoPerson size={32} />
            </div>
            <div className="flex inline-flex items-center h-16 bg-brand-100 dark:bg-brand-900 rounded-lg">
              {name.open ?
                <TextField
                  name="name"
                  className="!bg-brand-100 dark:!bg-brand-900 rounded-lg absolute"
                  sx={{
                    minWidth: 320
                  }}
                  style={{
                    width: '100%',
                    marginTop: 10, marginBottom: 10,
                    borderRadius: 5
                  }}
                  sid="outlined-basic" label={t('fullname')} variant="filled"
                  autoComplete="off"
                  color='primary'
                  inputProps={{
                    className: "text-brand-900 dark:text-brand-200",
                    style: {
                      fontFamily: "Jost, sans-serif",
                      fontSize: 16
                    }
                  }}
                  autoFocus
                  InputLabelProps={{
                    className: "text-brand-900 dark:text-brand-200",
                    style: {
                      fontSize: 17, paddingBottom: 5,
                      fontFamily: "Jost, sans-serif"
                    }, shrink: true
                  }}
                  value={name.value}
                  onChange={(e) => SetName({ ...name, ['value']: e.target.value })}
                  onKeyPress={PermiteSoloLetras}
                  type="text"
                /> :
                userInfo?.name
              }
            </div>
            <div className="absolute right-0 flex inline-flex items-center h-16">
              {
                !name.open ?
                  <Tooltip title={t('editfullname')} arrow={true}>
                    <div className="bg-brand-100 dark:bg-brand-900 rounded-full">
                      <IconButton onClick={() => handleOpenEditInfo('name')} color="primary">
                        <MdEdit size={32} className="text-yellow-600" />
                      </IconButton>
                    </div>
                  </Tooltip>
                  :
                  <Tooltip title={t('updatefullname')} arrow={true}>
                    <div className="bg-brand-100 dark:bg-brand-900 rounded-full">
                      <IconButton onClick={() => handleUpdateProfileIfo('name')} color="primary">
                        <MdSave size={32} className="text-brand-900 dark:text-brand-100" />
                      </IconButton>
                    </div>
                  </Tooltip>
              }
            </div>
          </div>


          <div className="relative w-full md:min-w-[500px] flex items-center mt-5 shadow-lg shadow-brand-300 dark:shadow-black bg-brand-100 dark:bg-brand-900 text-brand-900 dark:text-brand-100">
            <div className="rounded-full bg-brand-100 dark:bg-brand-900 w-16 h-16 flex inline-flex items-center justify-center mr-0">
              <FaFingerprint size={32} />
            </div>
            <div className="flex inline-flex items-center h-16 bg-brand-100 dark:bg-brand-900 rounded-lg">
              {password.open ?
                <div className="flex items-center">
                  <TextField
                    name="current"
                    className="!bg-brand-100 dark:!bg-brand-900 rounded-lg absolute"
                    sx={{
                      maxWidth: 170
                    }}
                    style={{
                      width: '100%',
                      marginTop: 10, marginBottom: 10,
                      borderRadius: 5
                    }}
                    sid="outlined-basic" label={t('currentpassword')} variant="filled"
                    autoComplete="off"
                    color='primary'
                    inputProps={{
                      className: "text-brand-900 dark:text-brand-200",
                      style: {
                        fontFamily: "Jost, sans-serif",
                        fontSize: 16
                      }
                    }}
                    autoFocus
                    InputLabelProps={{
                      className: "text-brand-900 dark:text-brand-200",
                      style: {
                        fontSize: 17, paddingBottom: 5,
                        fontFamily: "Jost, sans-serif"
                      }, shrink: true
                    }}
                    value={password.current}
                    onChange={(e) => SetPassword({ ...password, current: e.target.value })}
                    type="password"
                  />
                  <TextField
                    name="password"
                    className="!bg-brand-100 dark:!bg-brand-900 rounded-lg absolute"
                    sx={{
                      maxWidth: 170
                    }}
                    style={{
                      width: '100%',
                      marginTop: 10, marginBottom: 10,
                      borderRadius: 5
                    }}
                    sid="outlined-basic" label={t('newpassword')} variant="filled"
                    autoComplete="off"
                    color='primary'
                    inputProps={{
                      className: "text-brand-900 dark:text-brand-200",
                      style: {
                        fontFamily: "Jost, sans-serif",
                        fontSize: 16
                      }
                    }}
                    InputLabelProps={{
                      className: "text-brand-900 dark:text-brand-200",
                      style: {
                        fontSize: 17, paddingBottom: 5,
                        fontFamily: "Jost, sans-serif"
                      }, shrink: true
                    }}
                    value={password.value}
                    onChange={(e) => SetPassword({ ...password, value: e.target.value })}
                    type="password"
                  />
                </div>
                :
                '********'
              }
            </div>
            <div className="absolute right-0 flex inline-flex items-center h-16">
              {
                !password.open ?
                  <Tooltip title="editar contraseña" arrow={true}>
                    <div className="bg-brand-100 dark:bg-brand-900 rounded-full">
                      <IconButton onClick={() => handleOpenEditInfo('password')} color="primary">
                        <MdEdit size={32} className="text-yellow-600" />
                      </IconButton>
                    </div>
                  </Tooltip>
                  :
                  <Tooltip title="actualizar contraseña" arrow={true}>
                    <div className="bg-brand-100 dark:bg-brand-900 rounded-full">
                      <IconButton onClick={() => handleUpdateProfileIfo('password')} color="primary">
                        <MdSave size={32} className="text-brand-900 dark:text-brand-100" />
                      </IconButton>
                    </div>
                  </Tooltip>
              }
            </div>
          </div>

          <div className="relative w-full md:min-w-[500px] flex items-center mt-5 shadow-lg shadow-brand-300 dark:shadow-black bg-brand-100 dark:bg-brand-900 text-brand-900 dark:text-brand-100">
            <div className="rounded-full bg-brand-100 dark:bg-brand-900 w-16 h-16 flex inline-flex items-center justify-center">
              <MdEmail size={32} />
            </div>
            <div className="flex inline-flex items-center h-16 bg-brand-100 dark:bg-brand-900 rounded-lg">
              {
                email.open ?
                  <TextField
                    name="email"
                    className="bg-brand-100 dark:bg-brand-900 rounded-lg"
                    sx={{
                      minWidth: 320
                    }}
                    style={{
                      width: '100%',
                      marginTop: 10, marginBottom: 10, color: '#c2c2c2',
                      borderRadius: 5
                    }}
                    sid="outlined-basic" label={t('email')} variant="filled"
                    autoComplete="off"
                    color='primary'
                    inputProps={{
                      className: "text-brand-900 dark:text-brand-200",
                      style: {
                        fontFamily: "Jost, sans-serif",
                        fontSize: 16
                      }
                    }}
                    autoFocus
                    InputLabelProps={{
                      className: "text-brand-900 dark:text-brand-200",
                      style: {
                        fontSize: 17, paddingBottom: 5,
                        fontFamily: "Jost, sans-serif"
                      }, shrink: true
                    }}
                    value={email.value}
                    onChange={(e) => SetEmail({ ...email, ['value']: e.target.value })}
                    type="email"
                  /> :
                  userInfo?.email
              }
            </div>
            <div className="absolute right-0 flex inline-flex items-center h-16">
              <>
                {
                  !email.open ?
                    <Tooltip title={t('editemail')} arrow={true}>
                      <div className="bg-brand-100 dark:bg-brand-900 rounded-full">
                        <IconButton onClick={() => handleOpenEditInfo('email')} color="primary">
                          <MdEdit size={32} className="text-yellow-600" />
                        </IconButton>
                      </div>
                    </Tooltip>
                    :
                    <Tooltip title={t('updateemail')} arrow={true}>
                      <div className="bg-brand-100 dark:bg-brand-900 rounded-full">
                        <IconButton onClick={() => handleUpdateProfileIfo('email')} color="primary">
                          <MdSave size={32} className="text-brand-900 dark:text-brand-100" />
                        </IconButton>
                      </div>
                    </Tooltip>
                }
              </>
            </div>
          </div>

          <div className="relative w-full md:min-w-[500px] flex items-center mt-5 shadow-lg shadow-brand-300 dark:shadow-black bg-brand-100 dark:bg-brand-900 text-brand-900 dark:text-brand-100">
            <div className="rounded-full bg-brand-100 dark:bg-brand-900 w-16 h-16 flex inline-flex items-center justify-center">
              <FaCoins size={32} />
            </div>
            <div className="flex inline-flex items-center h-16 bg-brand-100 dark:bg-brand-900 rounded-lg">
              {currencyFormat(userInfo?.credits_purchased - userInfo?.credits_used)}
            </div>
          </div>

          <div className="relative w-full md:min-w-[500px] flex items-center mt-5 shadow-lg shadow-brand-300 dark:shadow-black bg-brand-100 dark:bg-brand-900 text-brand-900 dark:text-brand-100">
            <div className="rounded-full bg-brand-100 dark:bg-brand-900 w-16 h-16 flex inline-flex items-center justify-center">
              <BsCashCoin size={32} />
            </div>
            <div className="flex inline-flex items-center h-16 bg-brand-100 dark:bg-brand-900 rounded-lg">
              {userInfo?.reseller ? <span className="text-yellow-400">{t('isreseller')}</span> : <Tooltip title={`${t('howtoreseller')} ${configuration?.howmuchforreseller} ${t('credits')}`}><span className="text-red-600">{t('noisreseller')}</span></Tooltip>}
            </div>
          </div>

          <div className="relative w-full md:min-w-[500px] flex items-center mt-5 shadow-lg shadow-brand-300 dark:shadow-black bg-brand-100 dark:bg-brand-900 text-brand-900 dark:text-brand-100">
            <div className="rounded-full bg-brand-100 dark:bg-brand-900 w-16 h-16 flex inline-flex items-center justify-center">
              <RiVipLine size={32} />
            </div>
            <CardVip vip={userInfo?.vip} />
            {(userInfo?.vip?.state === 'ACTIVE' && new Date(userInfo?.vip?.expirationdate) > new Date()) && <span className="ml-5">{moment(userInfo?.vip?.expirationdate).format("dddd, DD [de] MMM [de] yyyy")}</span>}
          </div>

          <div className="relative w-full md:min-w-[500px] flex items-center mt-5 shadow-lg shadow-brand-300 dark:shadow-black bg-brand-100 dark:bg-brand-900 text-brand-900 dark:text-brand-100">
            <div className="rounded-full bg-brand-100 dark:bg-brand-900 w-16 h-16 flex inline-flex items-center justify-center">
              <FaPhone size={32} />
            </div>
            <div className="flex inline-flex items-center h-16 bg-brand-100 dark:bg-brand-900 rounded-lg">
              {
                phone.open ?
                  <MuiTelInput
                    label={t('phonenumber')}
                    variant="filled"
                    InputLabelProps={{
                      style: {
                        color: '#e9ecef', fontSize: 17, paddingBottom: 5,
                        fontFamily: "Jost, sans-serif"
                      }, shrink: true
                    }}
                    inputProps={{ className: "text-brand-900 dark:text-brand-200" }}
                    autoFocus
                    value={phone.value}
                    defaultCountry={'PE'}
                    onChange={(newValue, info) => SetPhone({ ...phone, ['code']: info.countryCode, ['value']: newValue })}
                    className="bg-brand-100 dark:bg-brand-900 rounded-lg"
                  />
                  :
                  userInfo?.phonenumber
              }
            </div>
            <div className="absolute right-0 flex inline-flex items-center h-16">
              {
                !phone.open ?
                  <Tooltip title={t('editphonenumber')} arrow={true}>
                    <div className="bg-brand-100 dark:bg-brand-900 rounded-full">
                      <IconButton onClick={() => handleOpenEditInfo('phone')} color="primary">
                        <MdEdit size={32} className="text-yellow-600" />
                      </IconButton>
                    </div>
                  </Tooltip>
                  :
                  <Tooltip title={t('updatephonenumber')} arrow={true}>
                    <div className="bg-brand-100 dark:bg-brand-900 rounded-full">
                      <IconButton onClick={() => handleUpdateProfileIfo('phone')} color="primary">
                        <MdSave size={32} className="text-brand-900 dark:text-brand-100" />
                      </IconButton>
                    </div>
                  </Tooltip>
              }
            </div>
          </div>

          <div className="relative w-full md:min-w-[500px] flex items-center mt-5 shadow-lg shadow-brand-300 dark:shadow-black bg-brand-100 dark:bg-brand-900 text-brand-900 dark:text-brand-100">
            <div className="rounded-full bg-brand-100 dark:bg-brand-900 w-16 h-16 flex inline-flex items-center justify-center">
              <FaRegCalendarPlus size={32} />
            </div>
            <div className="flex inline-flex items-center h-16 bg-brand-100 dark:bg-brand-900 rounded-lg">
              {setUnixToTimeFormat('created_at', userInfo?.created_at)}
            </div>
          </div>
        </div>
      </div>

    </AccountList>

  );
};

export default Profile;
