import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

const resources = {
  en: {
    translation: {
      "btnwhatsapp": "Request a service by WhatsApp",
      "logindisabled": "The login is under maintenance, please retry in a few minutes.",
      "currentsession": "There is an open session, please close and try again.",
      "changetolight": "switch to light mode",
      "changetodark": "switch to dark mode",
      "rechargecredits": "recharge credits",
      "register": "Register",
      "login": "Login",
      "login...": "Entering...",
      "email": "Email",
      "password": "Password",
      "myaccount": "My account",
      "myorders": "My orders",
      "recharge": "Recarregar",
      "logout": "Logout",
      "hello": "Hello",
      "pagedescription": "offers a quality streaming service, you can enjoy movies, series, anime, music, documentaries, live TV and much more with your friends and family; At the best price on the market, what do you expect, register and acquire the service that you like the most.",
      "methodspay": "Payment methods",
      "contactus": "Contact us",
      "madeby": "Made by",
      "emailrequired": "email is required",
      "phonerequired": "cell phone number and alias is required",
      "termsaccept": "accept terms and conditions of service",
      "selectmonths": "select how many months you want to buy",
      "orderdetails": "Purchase information",
      "howmuchmonths": "How many months are you buying?",
      "days": "days",
      "month": "month",
      "months": "months",
      "autorenewservice": "Auto renew service",
      "autorenewservicedetails": "By activating this option your service will be automatically renewed when it expires, as long as you have a balance in your account.",
      "autorenew": "Auto renew",
      "enabled": "activated",
      "disabled": "deactivated",
      "dear": "Dear",
      "user": "user",
      "emailrequireddetails": "To complete your order it is necessary that you provide us with an email, in order to activate the service to that email, Thank you!",
      "required": "required",
      "sendbywhatsapp": "Send purchase by whatsapp",
      "sendorderbywhatsapp": "Send purchase by whatsapp",
      "orderdetailtoclient": "Order details will be sent to your client's whatsapp.",
      "senddetailsbywhatsapp": "Send purchase by whatsapp to my client",
      "clientinfo": "Client information",
      "forreseller": "Options for resellers",
      "forresellerdetails": "These options are for resellers to keep track of their clients.",
      "phonenumber": "Cell phone number",
      "whatsappreal": "Please use your client's whatsapp number, we will send order information to this number, Thank you!",
      "termsandconditions": "Terms and conditions of service",
      "agree1": "I agree with the",
      "agree2": "terms and conditions of service",
      "agree3": "Terms and conditions of service",
      "processing...": "Processing...",
      "pay": "Pay",
      "saved": "You save",
      "whitvip": "WITH VIP",
      "iwant": "I want it",
      "available": "available",
      "noavailable": "not available",
      "zone": "Region",
      "free": "free",
      "orderauto": "Instant digital delivery",
      "warranty30": "30 day warranty",
      "delivered30": "10-30 minute digital delivery",
      "accountcomplete": "Full Account",
      "renewable": "renewable",
      "norenewable": "non-renewable",
      "onescreen": "1 Screen",
      "wostock": "Out of stock",
      "buy": "Buy",
      "nodata": "No data",
      "thecategory": "The category",
      "nodataregister": "no data registered yet",
      "usemode": "Description and how to use",
      "services": "services",
      "myprofile": "My profile",
      "editemail": "edit email",
      "updateemail": "update email",
      "isreseller": "You are a reseller",
      "noisreseller": "You are not a reseller",
      "howtoreseller": "You become a reseller when you recharge",
      "credits": "credits",
      "editphonenumber": "edit cell phone",
      "updatephonenumber": "update cell phone",
      "fullname": "Full name",
      "editfullname": "edit name",
      "updatefullname": "update name",
      "currentpassword": "Current password",
      "newpassword": "New password",
      "infoaccount": "ACCOUNT INFORMATION",
      "infoprofiles": "PROFILE INFORMATION",
      "service": "Service",
      "servicetype": "Service type",
      "expiration": "Expiration",
      "name": "Name",
      "Pin": "PIN",
      "alertmonthsrenew": "You must select the number of months you are going to renew",
      "alertinvalidtoken": "Invalid token, this problem will be solved by re-entering the system",
      "mystreamings": "My streaming",
      "searchorders": "Search orders",
      "infoclient": "Your client information",
      "infogeneral": "General information",
      "suspended": "Suspended",
      "completed": "Completed",
      "pending": "Pending",
      "rejected": "Rejected",
      "locked": "Locked",
      "infoaccountlower": "Account information",
      "infoprofileslower": "Profile information",
      "emailcopied": "Email copied",
      "passwordcopied": "Password copied",
      "infocopied": "Information copied",
      "aliascopied": "Alias ​​copied",
      "copy": "copy",
      "wopassword": "No password",
      "renweservice": "Renew service",
      "howmuchmonthforrenew": "Number of months you want to renew",
      "totalforpay": "Total to pay",
      "emailpendingforactivation": "Email pending activation",
      "servicelocked": "Service blocked",
      "servicerejeccted": "Service blocked",
      "expired": "Expired",
      "norenew": "Did not renew",
      "totalcost": "Total cost",
      "expirationpending": "Expiry pending",
      "status": "Status",
      "viewdetails": "view details",
      "details": "Details",
      "renew": "Renew",
      "thisservicenorenew": "this service is not renewable",
      "nodatasearchorders": "No results in the search! or there are no registered orders",
      "searchbycategory": "Search orders by category",
      "mygiftcards": "My gift cards",
      "giftcards": "Gift cards",
      "priceunit": "Unit price",
      "code": "Code",
      "codecopied": "Code copied",
      "quantity": "Quantity",
      "mysmmorders": "My SMM Orders",
      "waitinganswer": "Waiting for response",
      "typemessage": "Type your message",
      "link": "Url",
      "startend": "Start/End",
      "progress": "progress",
      "missing": "missing",
      "inrevew": "Under review",
      "proccessing": "Processing",
      "refunded": "Refunded",
      "canceled": "Cancelled",
      "rpartial": "R. Partial",
      "loadingdatawait": "Loading orders, please Please wait",
      "loadingdatawaitrecharge": "Loading recharges, please wait",
      "report": "report",
      "actionforbided": "You do not have permission to perform this action",
      "needselectone": "You need to select at least one package",
      "minmaxamount": "The minimum amount is 4 and the maximum amount is 1000",
      "agreeimportant": "Important agreement",
      "agreeizipay": "The current amount does not include IziPay fees",
      "agreestripe": "The current amount does not include stripe fees",
      "agreemercadopago": "The current amount does not include mercadopago fees",
      "fees": "Fee",
      "wantcontinued": "Do you want to continue",
      "whit": "with",
      "scan": "Scan",
      "yapeinfo": "AFTER SENDING THE YAPE, PLEASE SEND THE CAPTURE TO WHATSAPP IN ORDER TO RECHARGE YOUR BALANCE",
      "binanceinfo": "AFTER PAYING, PLEASE SEND THE CAPTURE TO WHATSAPP IN ORDER TO RECHARGE YOUR BALANCE.",
      "cometowhatsapp": "Let's talk on whatsapp",
      "amount": "Amount",
      "rechangecash": "Balance recharge",
      "rechargemanualinfo": "When making a manual recharge, the credits are received after the administrator has verified that the payment was made correctly; estimated time 10 to 30 minutes.",
      "automaticupper": "AUTOMATIC",
      "rechargeautomaticinfo": "When you make an automatic recharge, the credits are received instantly after making the payment correctly.",
      "gotopay": "go to pay",
      "myrecharges": "My recharges",
      "searchrecharges": "Search recharges by method, id or amount",
      "origin": "Origin",
      "observations": "Observations",
      "nodatarecharges": "No results in the search! or there are no registered recharges",
      "manageprofile": "Manage my profile",
      "manageorders": "Manage orders",
      "socials": "Social networks",
      "managerecharges": "Manage recharges",
      "history": "History",
      "home": "Home",
      "light": "Light",
      "dark": "Dark",
      "quantitygc": "The quantity of gift cards must be greater than 0",
      "gcinfoabout": "How many gift cards do you want to buy? min: 1 - max: 10",
      "giftcard": "gift card",
      "add": "add",
      "remove": "remove",
      "availableservices": "Available services",
      "socialinfotobuy": "Url of page, image, video",
      "howputcommentaries": "Comments (1 per line)",
      "price": "Price",
      "acceptdescription": "I read the description and agree with the terms and conditions of the service",
      "qtyminmax": "Minimum/maximum quantity",
      "approxtime": "Approximate time",
      "linkexamplephone": "Link example on cell phone",
      "linkexampledesktop": "Link example on computer",
      "loadingserviceinfo": "Loading service information",
      "subsvip": "VIP SUBSCRIPTION",
      "savetomax": "AHORRA AL MAXIMO",
      "sloganvip": "Access exclusive prices for resellers and VIP offers, now",
      "moresales": "BEST SOLD",
      "stock": "Stock",
    }
  },
  es: {
    translation: {
      "btnwhatsapp": "Solicita un servicio por whatsapp",
      "logindisabled": "el login se encuentra en mantenimiento, por favor reintenta en unos minutos.",
      "currentsession": "existe una sesión abierta, porfavor cierra y vuelve a intentar.",
      "changetolight": "cambiar a modo claro",
      "changetodark": "cambiar a modo oscuro",
      "rechargecredits": "recargar créditos",
      "register": "Registro",
      "login": "Ingresar",
      "login...": "Ingresando...",
      "email": "Correo",
      "password": "Contraseña",
      "myaccount": "Mi cuenta",
      "myorders": "Mis pedidos",
      "recharge": "Recargar",
      "logout": "Salir",
      "hello": "Hola",
      "pagedescription": "ofrece un servicio de streaming de calidad, podras disfrutar con tus amigos y familia, películas, series, animes, musica, documentales, tv en vivo y mucho mas; al mejor precio del mercado, que esperas registrate y adquiere el servicio que más te guste.",
      "methodspay": "Métodos de pago",
      "contactus": "Contáctanos",
      "madeby": "Hecho por",
      "emailrequired": "email es requerido",
      "phonerequired": "numero celular y alias es requerido",
      "termsaccept": "aceptar términos y condiciones del servicio",
      "selectmonths": "selecciona cuántos meses quieres comprar",
      "orderdetails": "Información de la compra",
      "howmuchmonths": "Cuántos meses estás comprando ?",
      "days": "días",
      "month": "mes",
      "months": "meses",
      "autorenewservice": "Autorenovar servicio",
      "autorenewservicedetails": "Al activar esta opción su servicio se renovará de forma automática cuando expire, siempre y cuando tenga saldo en su cuenta.",
      "autorenew": "Autorenovar",
      "enabled": "activado",
      "disabled": "desactivado",
      "dear": "Estimado",
      "user": "usuario",
      "emailrequireddetails": "para completar su pedido es necesario que nos brinde un correo electronico, para poder activar el servicio a ese correo, Gracias!",
      "required": "requerido",
      "sendbywhatsapp": "Enviar compra por whatsapp",
      "sendorderbywhatsapp": "Enviar compra por whatsapp",
      "orderdetailtoclient": "Se enviará los detalles del pedidos al whatsapp de tu cliente.",
      "senddetailsbywhatsapp": "Enviar compra por whatsapp a mi cliente",
      "clientinfo": "Información del cliente",
      "forreseller": "Opciones para reseller",
      "forresellerdetails": "Estas opciones son para que los reseller lleven un control de sus clientes.",
      "phonenumber": "Numero celular",
      "whatsappreal": "Porfavor use el numero de whatsapp de su cliente, le enviaremos información del pedido a este numero, Gracias!",
      "termsandconditions": "Términos y condiciones del servicio",
      "agree1": "Estoy de acuerdo con los",
      "agree2": "términos y condiciones del servicio",
      "agree3": "Términos y condiciones del servicio",
      "proccessing...": "Procesando...",
      "pay": "Pagar",
      "saved": "Ahorras",
      "whitvip": "CON VIP",
      "iwant": "Lo quiero",
      "available": "disponible",
      "noavailable": "no disponible",
      "zone": "Región",
      "free": "gratis",
      "orderauto": "Entrega digital instantánea",
      "warranty30": "Garantía 30 días",
      "delivered30": "Entrega digital 10-30 minutos",
      "accountcomplete": "Cuenta Completa",
      "renewable": "renovable",
      "norenewable": "no renovable",
      "onescreen": "1 Pantalla",
      "wostock": "Sin stock",
      "buy": "Comprar",
      "nodata": "Sin datos",
      "thecategory": "La categoría",
      "nodataregister": "aún no tiene datos registrados",
      "usemode": "Descripción y modo de uso",
      "services": "servicios",
      "myprofile": "Mi perfil",
      "editemail": "editar email",
      "updateemail": "actualizar email",
      "isreseller": "Eres revendedor",
      "noisreseller": "No eres revendedor",
      "howtoreseller": "Te conviertes en revendedor cuando recargas",
      "credits": "créditos",
      "editphonenumber": "editar celular",
      "updatephonenumber": "actualizar celular",
      "fullname": "Nombre completo",
      "editfullname": "editar nombre",
      "updatefullname": "actualizar nombre",
      "currentpassword": "Contraseña actual",
      "newpassword": "Nueva contraseña",
      "infoaccount": "INFORMACIÓN DE LA CUENTA",
      "infoprofiles": "INFORMACIÓN DE PERFILES",
      "service": "Servicio",
      "servicetype": "Tipo de servicio",
      "expiration": "Expiración",
      "name": "Nombre",
      "Pin": "PIN",
      "alertmonthsrenew": "Debes seleccionar la cantidad de meses que vas a renovar",
      "alertinvalidtoken": "Token inválido, este problema se resolverá reingresando al sistema",
      "mystreamings": "Mis streaming",
      "searchorders": "Buscar pedidos",
      "infoclient": "Información de tu cliente",
      "infogeneral": "Información general",
      "suspended": "Suspendido",
      "completed": "Completado",
      "pending": "Pendiente",
      "rejected": "Rechazado",
      "locked": "Bloqueado",
      "infoaccountlower": "Información de la cuenta",
      "infoprofileslower": "Información de perfiles",
      "emailcopied": "Correo copiado",
      "passwordcopied": "Contraseña copiada",
      "infocopied": "Información copiada",
      "aliascopied": "Alias copiado",
      "copy": "copiar",
      "wopassword": "Sin contraseña",
      "renweservice": "Renovar servicio",
      "howmuchmonthforrenew": "Cantidad de meses que quieres renovar",
      "totalforpay": "Total a pagar",
      "emailpendingforactivation": "Correo pendiente de activación",
      "servicelocked": "Servicio bloqueado",
      "servicerejeccted": "Servicio bloqueado",
      "expired": "Expirado",
      "norenew": "No hizo renovación",
      "totalcost": "Costo total",
      "expirationpending": "Expiración pendiente",
      "status": "Estado",
      "viewdetails": "ver detalles",
      "details": "Detalles",
      "renew": "Renovar",
      "thisservicenorenew": "este servicio no es renovable",
      "nodatasearchorders": "Sin resultados en la búsqueda! o no existen pedidos registrados",
      "searchbycategory": "Buscar pedidos por categoría",
      "mygiftcards": "Mis trajetas de regalo",
      "giftcards": "Tarjetas de regalo",
      "priceunit": "Precio unitario",
      "code": "Código",
      "codecopied": "código copiado",
      "quantity": "Cantidad",
      "mysmmorders": "Mis SMM Pedidos",
      "waitinganswer": "esperando respuesta",
      "typemessage": "Escribe tu mensaje",
      "link": "Url",
      "startend": "Inicio/Final",
      "progress": "progreso",
      "missing": "faltan",
      "inrevew": "En revisión",
      "proccessing": "Procesando",
      "refunded": "Reembolsado",
      "canceled": "Cancelado",
      "rpartial": "R. Parcial",
      "loadingdatawait": "Cargando pedidos, por favor espere",
      "loadingdatawaitrecharge": "Cargando reacargas, por favor espere",
      "report": "reportar",
      "actionforbided": "No tienes permiso para realizar esta acción",
      "needselectone": "Necesitas seleccionar almenos un paquete",
      "minmaxamount": "El monto mínimo es 4 y el monto máximo es 1000",
      "agreeimportant": "Acuerdo importante",
      "agreeizipay": "El monto actual no incluye tarifas de IziPay",
      "agreestripe": "El monto actual no incluye tarifas de stripe",
      "agreemercadopago": "El monto actual no incluye tarifas de mercadopago",
      "fees": "Tarifa",
      "wantcontinued": "quieres continuar",
      "whit": "con",
      "scan": "Escanear",
      "yapeinfo": "DESPUÉS DE ENVIAR EL YAPE, POR FAVOR ENVIAR LA CAPTURA AL WHATSAPP A FIN DE RECARGAR SU SALDO",
      "binanceinfo": "DESPUÉS DE PAGAR, POR FAVOR ENVIAR LA CAPTURA AL WHATSAPP A FIN DE RECARGAR SU SALDO.",
      "cometowhatsapp": "Hablemos por whatsapp",
      "amount": "Monto",
      "rechangecash": "Recarga de saldo",
      "rechargemanualinfo": "Al realizar una recarga manual los créditos son recibidos después que el administrador haya verificado que el pago se realizó de forma correcta; tiempo estimado 10 a 30 minutos.",
      "automaticupper": "AUTOMÁTICO",
      "rechargeautomaticinfo": "Al realizar una recarga automática los créditos son recibidos instantaneamente después de realizar el pago de forma correcta.",
      "gotopay": "ir a pagar",
      "myrecharges": "Mis recargas",
      "searchrecharges": "Buscar recargas por método, id o monto",
      "origin": "Origen",
      "observations": "Observaciones",
      "nodatarecharges": "Sin resultados en la búsqueda! o no existen recargas registradas",
      "manageprofile": "Gestionar mi perfil",
      "manageorders": "Gestionar pedidos",
      "socials": "Redes sociales",
      "managerecharges": "Gestionar recargas",
      "history": "Historial",
      "home": "Inicio",
      "light": "Claro",
      "dark": "Oscuro",
      "quantitygc": "La cantidad de tarjetas de regalo debe ser mayor a 0",
      "gcinfoabout": "Cuántas tarjetas de regalo quieres comprar ? min: 1 - max: 10",
      "giftcard": "tarjeta de regalo",
      "add": "añadir",
      "remove": "quitar",
      "availableservices": "Servicios disponibles",
      "socialinfotobuy": "Url de la página, imagen, video",
      "howputcomentaries": "Comentarios (1 por línea)",
      "price": "Precio",
      "acceptdescription": "Eh leído la descripción y estoy de acuerdo con los términos y condiciones del servicio",
      "qtyminmax": "Cantidad mínimo/máximo",
      "aproxtime": "Tiempo aproximado",
      "linkexamplephone": "Link ejemplo en celular",
      "linkexampledesktop": "Link ejemplo en computadora",
      "loadingserviceinfo": "Cargando información del servicio",
      "subsvip": "SUBSCRIPCION VIP",
      "savetomax": "AHORRA AL MAXIMO",
      "sloganvip": "Accede a precios exclusivos para revendedores y ofertas VIP, ahorra ahora",
      "moresales": "MÁS VENDIDO",
      "stock": "Stock"


    }
  },
  pt: {
    translation: {
      "btnwhatsapp": "Solicite um atendimento pelo WhatsApp",
      "logindisabled": "O login está em manutenção, tente novamente em alguns minutos.",
      "currentsession": "Há uma sessão aberta. Feche e tente novamente.",
      "changetolight": "mudar para o modo claro",
      "changetodark": "mudar para o modo escuro",
      "rechargecredits": "recarregar créditos",
      "register": "Registro",
      "login": "Entre",
      "login...": "Entrando...",
      "email": "Email",
      "password": "Senha",
      "myaccount": "minha conta",
      "myorders": "Meus pedidos",
      "recharge": "Recarregar",
      "logout": "Sair",
      "hello": "Olá",
      "pagedescription": "oferece um serviço de streaming de qualidade, você pode curtir filmes, séries, animes, músicas, documentários, TV ao vivo e muito mais com seus amigos e familiares; Ao melhor preço do mercado, o que você espera, cadastre-se e adquira o serviço que mais gosta.",
      "methodspay": "Métodos de pagamento",
      "contactus": "Contate-nos",
      "madeby": "feito por",
      "emailrequired": "e-mail é obrigatório",
      "phonerequired": "número de telefone celular e alias são obrigatórios",
      "termsaccept": "Aceitar termos e condições de serviço",
      "selectmonths": "Selecione quantos meses você deseja comprar",
      "orderdetails": "Informações de compra",
      "howmuchmonths": "Quantos meses você está comprando?",
      "days": "dias",
      "month": "mês",
      "months": "meses",
      "autorenewservice": "Serviço de renovação automática",
      "autorenewservicedetails": "Ao ativar esta opção seu serviço será renovado automaticamente quando expirar, desde que você tenha saldo em sua conta.",
      "autorenew": "Autorenew",
      "enabled": "ativado",
      "disabled": "desativado",
      "dear": "querido",
      "user": "usuário",
      "emailrequireddetails": "para finalizar seu pedido é necessário que você nos forneça um endereço de e-mail, para que possamos ativar o serviço para esse e-mail, Obrigado!",
      "required": "obrigatório",
      "sendbywhatsapp": "Enviar compra por whatsapp",
      "sendorderbywhatsapp": "Enviar compra por whatsapp",
      "orderdetailtoclient": "Os detalhes do pedido serão enviados para o WhatsApp do seu cliente.",
      "senddetailsbywhatsapp": "Enviar compra via WhatsApp para meu cliente",
      "clientinfo": "Informações do cliente",
      "forreseller": "Opções de revendedor",
      "forresellerdetails": "Essas opções são para os revendedores acompanharem seus clientes.",
      "phonenumber": "Número de telefone celular",
      "whatsappreal": "Por favor, utilize o número de WhatsApp do seu cliente, enviaremos as informações do pedido para este número, obrigado!",
      "termsandconditions": "Termos e condições de serviço",
      "agree1": "Eu concordo com eles",
      "agree2": "termos e condições de serviço",
      "agree3": "Termos e condições de serviço",
      "processando...": "Processando...",
      "pay": "Pagar",
      "saved": "Você salva",
      "whitvip": "COM VIP",
      "iwant": "Eu quero",
      "available": "disponível",
      "noavailable": "não disponível",
      "zone": "Região",
      "free": "grátis",
      "orderauto": "Entrega digital instantânea",
      "warranty30": "Garantia de 30 dias",
      "delivered30": "Entrega digital em 10 a 30 minutos",
      "accountcomplete": "Conta concluída",
      "renewable": "renovável",
      "norenewable": "não renovável",
      "onescreen": "1 Tela",
      "wostock": "Esgotado",
      "buy": "Comprar",
      "nodata": "Sem dados",
      "thecategory": "A categoria",
      "nodataregister": "nenhum dado registrado ainda",
      "usemode": "Descrição e modo de uso",
      "services": "serviços",
      "myprofile": "Meu perfil",
      "editemail": "editar e-mail",
      "updateemail": "atualizar e-mail",
      "isreseller": "Você é um revendedor",
      "noisreseller": "Você não é um revendedor",
      "howtoreseller": "Você se torna um revendedor ao recarregar",
      "credits": "créditos",
      "editphonenumber": "editar celular",
      "updatephonenumber": "atualizar celular",
      "fullname": "Nome completo",
      "editfullname": "editar nome",
      "updatefullname": "atualizar nome completo",
      "currentpassword": "Senha atual",
      "newpassword": "Nova senha",
      "infoaccount": "INFORMAÇÕES DA CONTA",
      "infoprofiles": "INFORMAÇÕES DE PERFIL",
      "service": "Serviço",
      "servicetype": "Tipo de serviço",
      "expiration": "Expiração",
      "name": "Nome",
      "pin": "PIN",
      "alertmonthsrenew": "Você deve selecionar o número de meses que irá renovar",
      "alertinvalidtoken": "Token inválido, este problema será resolvido entrando novamente no sistema",
      "mystreamings": "Meus streamings",
      "searchorders": "Pesquisar ordens",
      "infoclient": "Suas informações de cliente",
      "infogeneral": "Informações gerais",
      "suspended": "Suspenso",
      "completed": "Concluído",
      "pending": "Pendente",
      "rejected": "Rejeitado",
      "locked": "Bloqueado",
      "infoaccountlower": "Informações da conta",
      "infoprofileslower": "Informações do perfil",
      "emailcopied": "E-mail copiado",
      "passwordcopied": "Senha copiada",
      "infocopied": "Informações copiadas",
      "aliascopied": "Alias ​​​​copiado",
      "copy": "copiar",
      "wopassword": "Sem senha",
      "renweservice": "Renovar serviço",
      "howmuchmonthforrenew": "Número de meses que você deseja renovar",
      "totalforpay": "Total a pagar",
      "emailpendingforactivation": "E-mail com ativação pendente",
      "servicelocked": "Serviço bloqueado",
      "servicerejected": "Serviço bloqueado",
      "expired": "Expirado",
      "norenew": "Nenhuma renovação foi feita",
      "totalcost": "Custo total",
      "expirationpending": "Expiração pendente",
      "status": "Estado",
      "viewdetails": "ver detalhes",
      "details": "Detalhes",
      "renew": "Renovar",
      "thisservicenorenew": "este serviço não é renovável",
      "nodatasearchorders": "Nenhum resultado para a pesquisa! ou nenhum pedido registrado",
      "searchbycategory": "Pesquisar pedidos por categoria",
      "mygiftcards": "Meus cartões-presente",
      "giftcards": "Cartões-presente",
      "priceunit": "Preço unitário",
      "código": "Código",
      "codecopied": "código copiado",
      "quantidade": "Quantidade",
      "mysmmorders": "Meus pedidos SMM",
      "waitinganswer": "aguardando resposta",
      "typemessage": "Escreva sua mensagem",
      "link": "URL",
      "startend": "Início/Fim",
      "progress": "progresso",
      "missing": "Ausente",
      "inrevew": "Em revisão",
      "proccessing": "Processamento",
      "reembolsado": "Reembolsado",
      "cancelado": "Cancelado",
      "rpartial": "R. Parcial",
      "loadingdatawait": "Carregando pedidos, aguarde",
      "loadingdatawaitrecharge": "Carregando recargas, aguarde",
      "report": "relatório",
      "actionforbided": "Você não tem permissão para executar esta ação",
      "needselectone": "Você precisa selecionar pelo menos um pacote",
      "minmaxamount": "O valor mínimo é 4 e o valor máximo é 1000",
      "agreeimportant": "Acordo importante",
      "agreeizipay": "O valor atual não inclui as taxas do IziPay",
      "agreestripe": "O valor atual não inclui taxas de stripe",
      "agreemercadopago": "O valor atual não inclui as taxas do Mercadopago",
      "fees": "Taxa",
      "wantcontinued": "você quer continuar",
      "whit": "com",
      "scan": "Escanear",
      "yapeinfo": "APÓS ENVIAR O YAPE, POR FAVOR ENVIE A CAPTURA PARA O WHATSAPP PARA RECARREGAR SEU SALDO",
      "binanceinfo": "APÓS PAGAR, ENVIE O ROTEIRO PARA O WHATSAPP PARA RECARREGAR SEU SALDO.",
      "cometowhatsapp": "Vamos conversar no WhatsApp",
      "quantity": "Quantidade",
      "rechangecash": "Recarga de saldo",
      "rechargemanualinfo": "Ao realizar uma recarga manual, os créditos são recebidos após o administrador verificar se o pagamento foi feito corretamente; tempo estimado de 10 a 30 minutos.",
      "automaticupper": "AUTOMÁTICO",
      "rechargeautomaticinfo": "Ao realizar uma recarga automática, os créditos são recebidos instantaneamente após efetuar o pagamento corretamente.",
      "gotopay": "ir pagar",
      "myrecharges": "Minhas recargas",
      "searchrecharges": "Pesquisar recargas por método, id ou valor",
      "origin": "Origem",
      "observations": "Observações",
      "nodatarecharges": "Nenhum resultado na busca! ou não há recargas registradas",
      "manageprofile": "Gerenciar meu perfil",
      "manageorders": "Gerenciar pedidos",
      "socials": "Redes sociais",
      "managerecharges": "Gerenciar recargas",
      "history": "História",
      "home": "Começo",
      "light": "Claro",
      "dark": "Escuro",
      "quantitygc": "A quantidade de cartões-presente deve ser maior que 0",
      "gcinfoabout": "Quantos cartões-presente você deseja comprar? min: 1 - máx: 10",
      "giftcard": "cartão presente",
      "add": "adicionar",
      "remove": "remover",
      "availableservices": "Serviços disponíveis",
      "socialinfotobuy": "URL da página, imagem, vídeo",
      "howputcomentaries": "Comentários (1 por linha)",
      "price": "Preço",
      "acceptdescription": "Eu li a descrição e estou de acordo com os termos e condições do serviço",
      "qtyminmax": "Quantidade mínima/máxima",
      "aproxtime": "Tempo aproximado",
      "linkexamplephone": "Link exemplo em celular",
      "linkexampledesktop": "Link exemplo no computador",
      "loadingserviceinfo": "Carregando informações de serviço",
      "subsvip": "ASSINATURA VIP",
      "savetomax": "AHORRA AL MAXIMO",
      "sloganvip": "Aceda a preços exclusivos para revendedores e ofertas VIP, agora mesmo",
      "moresales": "MÁS VENDIDO",
      "stock": "Estoque"
    }
  }
};

i18n
  .use(LanguageDetector) // Detecta el idioma del navegador
  .use(XHR) // Se puede usar para cargar traducciones dinámicamente
  .use(initReactI18next) // Conecta i18next con React
  .init({
    resources, // Definimos nuestros recursos de traducción
    lng: 'es', // Idioma por defecto (puedes configurarlo como prefieras)
    fallbackLng: 'en', // Idioma a usar si no está disponible
    interpolation: {
      escapeValue: false, // React ya escapa los valores, por lo que no necesitamos hacerlo
    },
    detection: {
      order: ['navigator', 'localStorage', 'querystring'], // La prioridad para detectar el idioma
      caches: ['localStorage'], // Guarda la preferencia en el localStorage
    },
  });

export default i18n;
